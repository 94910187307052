/*eslint-disable block-scoped-var, id-length, no-control-regex, no-magic-numbers, no-prototype-builtins, no-redeclare, no-shadow, no-var, sort-vars*/
"use strict";

var $protobuf = require("protobufjs/minimal");

var $Reader = $protobuf.Reader, $Writer = $protobuf.Writer, $util = $protobuf.util;

var $root = $protobuf.roots["default"] || ($protobuf.roots["default"] = {});

$root.enums = (function() {

    var enums = {};

    enums.ErrorNo = (function() {
        var valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "SUCCESS"] = 0;
        values[valuesById[1] = "FAIL"] = 1;
        values[valuesById[106] = "PAY_CHANNEL_CLOSE"] = 106;
        values[valuesById[801] = "ACCESS_TOKEN_EXPIRED"] = 801;
        values[valuesById[802] = "ACCESS_TOKEN_UNAUTHENTICATED"] = 802;
        values[valuesById[803] = "ACCESS_TOKEN_AUTH_FAIL"] = 803;
        values[valuesById[804] = "REQUIRE_RE_LOGIN"] = 804;
        values[valuesById[821] = "ACCESS_TOKEN_ERROR"] = 821;
        values[valuesById[822] = "REFRESH_TOKEN_EXPIRED"] = 822;
        values[valuesById[823] = "REFRESH_TOKEN_ILLEGAL"] = 823;
        values[valuesById[824] = "REFRESH_TOKEN_INVALIDATE"] = 824;
        values[valuesById[500] = "ERROR"] = 500;
        values[valuesById[405] = "BAD_REQUEST"] = 405;
        values[valuesById[404] = "REQUEST_NOT_FOUND"] = 404;
        values[valuesById[300] = "MESSAGE"] = 300;
        values[valuesById[400] = "Param_Error"] = 400;
        values[valuesById[440] = "SERVICE_NOT_USABLE"] = 440;
        return values;
    })();

    enums.LoginType = (function() {
        var valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "_LoginType_UNSPECIFIED"] = 0;
        values[valuesById[1] = "LOGIN_BY_GOOGLE"] = 3;
        values[valuesById[2] = "LOGIN_BY_FB"] = 4;
        values[valuesById[3] = "LOGIN_BY_APPLE"] = 5;
        return values;
    })();

    enums.OrderState = (function() {
        var valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "_OrderState_UNSPECIFIED"] = 0;
        values[valuesById[1] = "OrderState_CREATED"] = 1;
        values[valuesById[2] = "OrderState_HASH_COMMIT"] = 2;
        values[valuesById[3] = "OrderState_DONE"] = 3;
        return values;
    })();

    return enums;
})();

$root.framework = (function() {

    var framework = {};

    framework.PBMessageRequest = (function() {

        function PBMessageRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        PBMessageRequest.prototype.messageData = $util.newBuffer([]);
        PBMessageRequest.prototype.version = "";

        PBMessageRequest.create = function create(properties) {
            return new PBMessageRequest(properties);
        };

        PBMessageRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.messageData != null && Object.hasOwnProperty.call(message, "messageData"))
                writer.uint32(10).bytes(message.messageData);
            if (message.version != null && Object.hasOwnProperty.call(message, "version"))
                writer.uint32(18).string(message.version);
            return writer;
        };

        PBMessageRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        PBMessageRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.framework.PBMessageRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.messageData = reader.bytes();
                        break;
                    }
                case 2: {
                        message.version = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        PBMessageRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        PBMessageRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.messageData != null && message.hasOwnProperty("messageData"))
                if (!(message.messageData && typeof message.messageData.length === "number" || $util.isString(message.messageData)))
                    return "messageData: buffer expected";
            if (message.version != null && message.hasOwnProperty("version"))
                if (!$util.isString(message.version))
                    return "version: string expected";
            return null;
        };

        PBMessageRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.framework.PBMessageRequest)
                return object;
            var message = new $root.framework.PBMessageRequest();
            if (object.messageData != null)
                if (typeof object.messageData === "string")
                    $util.base64.decode(object.messageData, message.messageData = $util.newBuffer($util.base64.length(object.messageData)), 0);
                else if (object.messageData.length >= 0)
                    message.messageData = object.messageData;
            if (object.version != null)
                message.version = String(object.version);
            return message;
        };

        PBMessageRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                if (options.bytes === String)
                    object.messageData = "";
                else {
                    object.messageData = [];
                    if (options.bytes !== Array)
                        object.messageData = $util.newBuffer(object.messageData);
                }
                object.version = "";
            }
            if (message.messageData != null && message.hasOwnProperty("messageData"))
                object.messageData = options.bytes === String ? $util.base64.encode(message.messageData, 0, message.messageData.length) : options.bytes === Array ? Array.prototype.slice.call(message.messageData) : message.messageData;
            if (message.version != null && message.hasOwnProperty("version"))
                object.version = message.version;
            return object;
        };

        PBMessageRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        PBMessageRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/framework.PBMessageRequest";
        };

        return PBMessageRequest;
    })();

    framework.PBMessageResponse = (function() {

        function PBMessageResponse(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        PBMessageResponse.prototype.errorNo = 0;
        PBMessageResponse.prototype.msg = "";
        PBMessageResponse.prototype.messageData = $util.newBuffer([]);

        PBMessageResponse.create = function create(properties) {
            return new PBMessageResponse(properties);
        };

        PBMessageResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.errorNo != null && Object.hasOwnProperty.call(message, "errorNo"))
                writer.uint32(8).int32(message.errorNo);
            if (message.msg != null && Object.hasOwnProperty.call(message, "msg"))
                writer.uint32(18).string(message.msg);
            if (message.messageData != null && Object.hasOwnProperty.call(message, "messageData"))
                writer.uint32(26).bytes(message.messageData);
            return writer;
        };

        PBMessageResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        PBMessageResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.framework.PBMessageResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.errorNo = reader.int32();
                        break;
                    }
                case 2: {
                        message.msg = reader.string();
                        break;
                    }
                case 3: {
                        message.messageData = reader.bytes();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        PBMessageResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        PBMessageResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.errorNo != null && message.hasOwnProperty("errorNo"))
                switch (message.errorNo) {
                default:
                    return "errorNo: enum value expected";
                case 0:
                case 1:
                case 106:
                case 801:
                case 802:
                case 803:
                case 804:
                case 821:
                case 822:
                case 823:
                case 824:
                case 500:
                case 405:
                case 404:
                case 300:
                case 400:
                case 440:
                    break;
                }
            if (message.msg != null && message.hasOwnProperty("msg"))
                if (!$util.isString(message.msg))
                    return "msg: string expected";
            if (message.messageData != null && message.hasOwnProperty("messageData"))
                if (!(message.messageData && typeof message.messageData.length === "number" || $util.isString(message.messageData)))
                    return "messageData: buffer expected";
            return null;
        };

        PBMessageResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.framework.PBMessageResponse)
                return object;
            var message = new $root.framework.PBMessageResponse();
            switch (object.errorNo) {
            default:
                if (typeof object.errorNo === "number") {
                    message.errorNo = object.errorNo;
                    break;
                }
                break;
            case "SUCCESS":
            case 0:
                message.errorNo = 0;
                break;
            case "FAIL":
            case 1:
                message.errorNo = 1;
                break;
            case "PAY_CHANNEL_CLOSE":
            case 106:
                message.errorNo = 106;
                break;
            case "ACCESS_TOKEN_EXPIRED":
            case 801:
                message.errorNo = 801;
                break;
            case "ACCESS_TOKEN_UNAUTHENTICATED":
            case 802:
                message.errorNo = 802;
                break;
            case "ACCESS_TOKEN_AUTH_FAIL":
            case 803:
                message.errorNo = 803;
                break;
            case "REQUIRE_RE_LOGIN":
            case 804:
                message.errorNo = 804;
                break;
            case "ACCESS_TOKEN_ERROR":
            case 821:
                message.errorNo = 821;
                break;
            case "REFRESH_TOKEN_EXPIRED":
            case 822:
                message.errorNo = 822;
                break;
            case "REFRESH_TOKEN_ILLEGAL":
            case 823:
                message.errorNo = 823;
                break;
            case "REFRESH_TOKEN_INVALIDATE":
            case 824:
                message.errorNo = 824;
                break;
            case "ERROR":
            case 500:
                message.errorNo = 500;
                break;
            case "BAD_REQUEST":
            case 405:
                message.errorNo = 405;
                break;
            case "REQUEST_NOT_FOUND":
            case 404:
                message.errorNo = 404;
                break;
            case "MESSAGE":
            case 300:
                message.errorNo = 300;
                break;
            case "Param_Error":
            case 400:
                message.errorNo = 400;
                break;
            case "SERVICE_NOT_USABLE":
            case 440:
                message.errorNo = 440;
                break;
            }
            if (object.msg != null)
                message.msg = String(object.msg);
            if (object.messageData != null)
                if (typeof object.messageData === "string")
                    $util.base64.decode(object.messageData, message.messageData = $util.newBuffer($util.base64.length(object.messageData)), 0);
                else if (object.messageData.length >= 0)
                    message.messageData = object.messageData;
            return message;
        };

        PBMessageResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.errorNo = options.enums === String ? "SUCCESS" : 0;
                object.msg = "";
                if (options.bytes === String)
                    object.messageData = "";
                else {
                    object.messageData = [];
                    if (options.bytes !== Array)
                        object.messageData = $util.newBuffer(object.messageData);
                }
            }
            if (message.errorNo != null && message.hasOwnProperty("errorNo"))
                object.errorNo = options.enums === String ? $root.enums.ErrorNo[message.errorNo] === undefined ? message.errorNo : $root.enums.ErrorNo[message.errorNo] : message.errorNo;
            if (message.msg != null && message.hasOwnProperty("msg"))
                object.msg = message.msg;
            if (message.messageData != null && message.hasOwnProperty("messageData"))
                object.messageData = options.bytes === String ? $util.base64.encode(message.messageData, 0, message.messageData.length) : options.bytes === Array ? Array.prototype.slice.call(message.messageData) : message.messageData;
            return object;
        };

        PBMessageResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        PBMessageResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/framework.PBMessageResponse";
        };

        return PBMessageResponse;
    })();

    return framework;
})();

$root.game = (function() {

    var game = {};

    game.GameListResponse = (function() {

        function GameListResponse(properties) {
            this.list = [];
            this.phonePrefix = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        GameListResponse.prototype.list = $util.emptyArray;
        GameListResponse.prototype.phonePrefix = $util.emptyArray;

        GameListResponse.create = function create(properties) {
            return new GameListResponse(properties);
        };

        GameListResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.list != null && message.list.length)
                for (var i = 0; i < message.list.length; ++i)
                    $root.game.GameItem.encode(message.list[i], writer.uint32(10).fork()).ldelim();
            if (message.phonePrefix != null && message.phonePrefix.length)
                for (var i = 0; i < message.phonePrefix.length; ++i)
                    writer.uint32(18).string(message.phonePrefix[i]);
            return writer;
        };

        GameListResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        GameListResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.game.GameListResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        if (!(message.list && message.list.length))
                            message.list = [];
                        message.list.push($root.game.GameItem.decode(reader, reader.uint32()));
                        break;
                    }
                case 2: {
                        if (!(message.phonePrefix && message.phonePrefix.length))
                            message.phonePrefix = [];
                        message.phonePrefix.push(reader.string());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        GameListResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        GameListResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.list != null && message.hasOwnProperty("list")) {
                if (!Array.isArray(message.list))
                    return "list: array expected";
                for (var i = 0; i < message.list.length; ++i) {
                    var error = $root.game.GameItem.verify(message.list[i]);
                    if (error)
                        return "list." + error;
                }
            }
            if (message.phonePrefix != null && message.hasOwnProperty("phonePrefix")) {
                if (!Array.isArray(message.phonePrefix))
                    return "phonePrefix: array expected";
                for (var i = 0; i < message.phonePrefix.length; ++i)
                    if (!$util.isString(message.phonePrefix[i]))
                        return "phonePrefix: string[] expected";
            }
            return null;
        };

        GameListResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.game.GameListResponse)
                return object;
            var message = new $root.game.GameListResponse();
            if (object.list) {
                if (!Array.isArray(object.list))
                    throw TypeError(".game.GameListResponse.list: array expected");
                message.list = [];
                for (var i = 0; i < object.list.length; ++i) {
                    if (typeof object.list[i] !== "object")
                        throw TypeError(".game.GameListResponse.list: object expected");
                    message.list[i] = $root.game.GameItem.fromObject(object.list[i]);
                }
            }
            if (object.phonePrefix) {
                if (!Array.isArray(object.phonePrefix))
                    throw TypeError(".game.GameListResponse.phonePrefix: array expected");
                message.phonePrefix = [];
                for (var i = 0; i < object.phonePrefix.length; ++i)
                    message.phonePrefix[i] = String(object.phonePrefix[i]);
            }
            return message;
        };

        GameListResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.arrays || options.defaults) {
                object.list = [];
                object.phonePrefix = [];
            }
            if (message.list && message.list.length) {
                object.list = [];
                for (var j = 0; j < message.list.length; ++j)
                    object.list[j] = $root.game.GameItem.toObject(message.list[j], options);
            }
            if (message.phonePrefix && message.phonePrefix.length) {
                object.phonePrefix = [];
                for (var j = 0; j < message.phonePrefix.length; ++j)
                    object.phonePrefix[j] = message.phonePrefix[j];
            }
            return object;
        };

        GameListResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        GameListResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/game.GameListResponse";
        };

        return GameListResponse;
    })();

    game.GameItem = (function() {

        function GameItem(properties) {
            this.phonePrefix = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        GameItem.prototype.id = "";
        GameItem.prototype.icon = "";
        GameItem.prototype.name = "";
        GameItem.prototype.phonePrefix = $util.emptyArray;

        GameItem.create = function create(properties) {
            return new GameItem(properties);
        };

        GameItem.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(10).string(message.id);
            if (message.icon != null && Object.hasOwnProperty.call(message, "icon"))
                writer.uint32(18).string(message.icon);
            if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                writer.uint32(26).string(message.name);
            if (message.phonePrefix != null && message.phonePrefix.length)
                for (var i = 0; i < message.phonePrefix.length; ++i)
                    writer.uint32(34).string(message.phonePrefix[i]);
            return writer;
        };

        GameItem.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        GameItem.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.game.GameItem();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.id = reader.string();
                        break;
                    }
                case 2: {
                        message.icon = reader.string();
                        break;
                    }
                case 3: {
                        message.name = reader.string();
                        break;
                    }
                case 4: {
                        if (!(message.phonePrefix && message.phonePrefix.length))
                            message.phonePrefix = [];
                        message.phonePrefix.push(reader.string());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        GameItem.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        GameItem.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.id != null && message.hasOwnProperty("id"))
                if (!$util.isString(message.id))
                    return "id: string expected";
            if (message.icon != null && message.hasOwnProperty("icon"))
                if (!$util.isString(message.icon))
                    return "icon: string expected";
            if (message.name != null && message.hasOwnProperty("name"))
                if (!$util.isString(message.name))
                    return "name: string expected";
            if (message.phonePrefix != null && message.hasOwnProperty("phonePrefix")) {
                if (!Array.isArray(message.phonePrefix))
                    return "phonePrefix: array expected";
                for (var i = 0; i < message.phonePrefix.length; ++i)
                    if (!$util.isString(message.phonePrefix[i]))
                        return "phonePrefix: string[] expected";
            }
            return null;
        };

        GameItem.fromObject = function fromObject(object) {
            if (object instanceof $root.game.GameItem)
                return object;
            var message = new $root.game.GameItem();
            if (object.id != null)
                message.id = String(object.id);
            if (object.icon != null)
                message.icon = String(object.icon);
            if (object.name != null)
                message.name = String(object.name);
            if (object.phonePrefix) {
                if (!Array.isArray(object.phonePrefix))
                    throw TypeError(".game.GameItem.phonePrefix: array expected");
                message.phonePrefix = [];
                for (var i = 0; i < object.phonePrefix.length; ++i)
                    message.phonePrefix[i] = String(object.phonePrefix[i]);
            }
            return message;
        };

        GameItem.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.arrays || options.defaults)
                object.phonePrefix = [];
            if (options.defaults) {
                object.id = "";
                object.icon = "";
                object.name = "";
            }
            if (message.id != null && message.hasOwnProperty("id"))
                object.id = message.id;
            if (message.icon != null && message.hasOwnProperty("icon"))
                object.icon = message.icon;
            if (message.name != null && message.hasOwnProperty("name"))
                object.name = message.name;
            if (message.phonePrefix && message.phonePrefix.length) {
                object.phonePrefix = [];
                for (var j = 0; j < message.phonePrefix.length; ++j)
                    object.phonePrefix[j] = message.phonePrefix[j];
            }
            return object;
        };

        GameItem.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        GameItem.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/game.GameItem";
        };

        return GameItem;
    })();

    game.ServerListRequest = (function() {

        function ServerListRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        ServerListRequest.prototype.appId = "";

        ServerListRequest.create = function create(properties) {
            return new ServerListRequest(properties);
        };

        ServerListRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.appId != null && Object.hasOwnProperty.call(message, "appId"))
                writer.uint32(10).string(message.appId);
            return writer;
        };

        ServerListRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        ServerListRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.game.ServerListRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.appId = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        ServerListRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        ServerListRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.appId != null && message.hasOwnProperty("appId"))
                if (!$util.isString(message.appId))
                    return "appId: string expected";
            return null;
        };

        ServerListRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.game.ServerListRequest)
                return object;
            var message = new $root.game.ServerListRequest();
            if (object.appId != null)
                message.appId = String(object.appId);
            return message;
        };

        ServerListRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults)
                object.appId = "";
            if (message.appId != null && message.hasOwnProperty("appId"))
                object.appId = message.appId;
            return object;
        };

        ServerListRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        ServerListRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/game.ServerListRequest";
        };

        return ServerListRequest;
    })();

    game.ServerListResponse = (function() {

        function ServerListResponse(properties) {
            this.list = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        ServerListResponse.prototype.list = $util.emptyArray;

        ServerListResponse.create = function create(properties) {
            return new ServerListResponse(properties);
        };

        ServerListResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.list != null && message.list.length)
                for (var i = 0; i < message.list.length; ++i)
                    $root.game.ServerItem.encode(message.list[i], writer.uint32(10).fork()).ldelim();
            return writer;
        };

        ServerListResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        ServerListResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.game.ServerListResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        if (!(message.list && message.list.length))
                            message.list = [];
                        message.list.push($root.game.ServerItem.decode(reader, reader.uint32()));
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        ServerListResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        ServerListResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.list != null && message.hasOwnProperty("list")) {
                if (!Array.isArray(message.list))
                    return "list: array expected";
                for (var i = 0; i < message.list.length; ++i) {
                    var error = $root.game.ServerItem.verify(message.list[i]);
                    if (error)
                        return "list." + error;
                }
            }
            return null;
        };

        ServerListResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.game.ServerListResponse)
                return object;
            var message = new $root.game.ServerListResponse();
            if (object.list) {
                if (!Array.isArray(object.list))
                    throw TypeError(".game.ServerListResponse.list: array expected");
                message.list = [];
                for (var i = 0; i < object.list.length; ++i) {
                    if (typeof object.list[i] !== "object")
                        throw TypeError(".game.ServerListResponse.list: object expected");
                    message.list[i] = $root.game.ServerItem.fromObject(object.list[i]);
                }
            }
            return message;
        };

        ServerListResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.arrays || options.defaults)
                object.list = [];
            if (message.list && message.list.length) {
                object.list = [];
                for (var j = 0; j < message.list.length; ++j)
                    object.list[j] = $root.game.ServerItem.toObject(message.list[j], options);
            }
            return object;
        };

        ServerListResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        ServerListResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/game.ServerListResponse";
        };

        return ServerListResponse;
    })();

    game.ServerItem = (function() {

        function ServerItem(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        ServerItem.prototype.id = "";
        ServerItem.prototype.name = "";

        ServerItem.create = function create(properties) {
            return new ServerItem(properties);
        };

        ServerItem.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(10).string(message.id);
            if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                writer.uint32(18).string(message.name);
            return writer;
        };

        ServerItem.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        ServerItem.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.game.ServerItem();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.id = reader.string();
                        break;
                    }
                case 2: {
                        message.name = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        ServerItem.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        ServerItem.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.id != null && message.hasOwnProperty("id"))
                if (!$util.isString(message.id))
                    return "id: string expected";
            if (message.name != null && message.hasOwnProperty("name"))
                if (!$util.isString(message.name))
                    return "name: string expected";
            return null;
        };

        ServerItem.fromObject = function fromObject(object) {
            if (object instanceof $root.game.ServerItem)
                return object;
            var message = new $root.game.ServerItem();
            if (object.id != null)
                message.id = String(object.id);
            if (object.name != null)
                message.name = String(object.name);
            return message;
        };

        ServerItem.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.id = "";
                object.name = "";
            }
            if (message.id != null && message.hasOwnProperty("id"))
                object.id = message.id;
            if (message.name != null && message.hasOwnProperty("name"))
                object.name = message.name;
            return object;
        };

        ServerItem.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        ServerItem.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/game.ServerItem";
        };

        return ServerItem;
    })();

    game.RoleListRequest = (function() {

        function RoleListRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        RoleListRequest.prototype.appId = "";
        RoleListRequest.prototype.serverId = "";
        RoleListRequest.prototype.accessToken = "";

        RoleListRequest.create = function create(properties) {
            return new RoleListRequest(properties);
        };

        RoleListRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.appId != null && Object.hasOwnProperty.call(message, "appId"))
                writer.uint32(10).string(message.appId);
            if (message.serverId != null && Object.hasOwnProperty.call(message, "serverId"))
                writer.uint32(18).string(message.serverId);
            if (message.accessToken != null && Object.hasOwnProperty.call(message, "accessToken"))
                writer.uint32(26).string(message.accessToken);
            return writer;
        };

        RoleListRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        RoleListRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.game.RoleListRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.appId = reader.string();
                        break;
                    }
                case 2: {
                        message.serverId = reader.string();
                        break;
                    }
                case 3: {
                        message.accessToken = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        RoleListRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        RoleListRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.appId != null && message.hasOwnProperty("appId"))
                if (!$util.isString(message.appId))
                    return "appId: string expected";
            if (message.serverId != null && message.hasOwnProperty("serverId"))
                if (!$util.isString(message.serverId))
                    return "serverId: string expected";
            if (message.accessToken != null && message.hasOwnProperty("accessToken"))
                if (!$util.isString(message.accessToken))
                    return "accessToken: string expected";
            return null;
        };

        RoleListRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.game.RoleListRequest)
                return object;
            var message = new $root.game.RoleListRequest();
            if (object.appId != null)
                message.appId = String(object.appId);
            if (object.serverId != null)
                message.serverId = String(object.serverId);
            if (object.accessToken != null)
                message.accessToken = String(object.accessToken);
            return message;
        };

        RoleListRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.appId = "";
                object.serverId = "";
                object.accessToken = "";
            }
            if (message.appId != null && message.hasOwnProperty("appId"))
                object.appId = message.appId;
            if (message.serverId != null && message.hasOwnProperty("serverId"))
                object.serverId = message.serverId;
            if (message.accessToken != null && message.hasOwnProperty("accessToken"))
                object.accessToken = message.accessToken;
            return object;
        };

        RoleListRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        RoleListRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/game.RoleListRequest";
        };

        return RoleListRequest;
    })();

    game.RoleListResponse = (function() {

        function RoleListResponse(properties) {
            this.list = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        RoleListResponse.prototype.list = $util.emptyArray;

        RoleListResponse.create = function create(properties) {
            return new RoleListResponse(properties);
        };

        RoleListResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.list != null && message.list.length)
                for (var i = 0; i < message.list.length; ++i)
                    $root.game.RoleItem.encode(message.list[i], writer.uint32(10).fork()).ldelim();
            return writer;
        };

        RoleListResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        RoleListResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.game.RoleListResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        if (!(message.list && message.list.length))
                            message.list = [];
                        message.list.push($root.game.RoleItem.decode(reader, reader.uint32()));
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        RoleListResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        RoleListResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.list != null && message.hasOwnProperty("list")) {
                if (!Array.isArray(message.list))
                    return "list: array expected";
                for (var i = 0; i < message.list.length; ++i) {
                    var error = $root.game.RoleItem.verify(message.list[i]);
                    if (error)
                        return "list." + error;
                }
            }
            return null;
        };

        RoleListResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.game.RoleListResponse)
                return object;
            var message = new $root.game.RoleListResponse();
            if (object.list) {
                if (!Array.isArray(object.list))
                    throw TypeError(".game.RoleListResponse.list: array expected");
                message.list = [];
                for (var i = 0; i < object.list.length; ++i) {
                    if (typeof object.list[i] !== "object")
                        throw TypeError(".game.RoleListResponse.list: object expected");
                    message.list[i] = $root.game.RoleItem.fromObject(object.list[i]);
                }
            }
            return message;
        };

        RoleListResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.arrays || options.defaults)
                object.list = [];
            if (message.list && message.list.length) {
                object.list = [];
                for (var j = 0; j < message.list.length; ++j)
                    object.list[j] = $root.game.RoleItem.toObject(message.list[j], options);
            }
            return object;
        };

        RoleListResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        RoleListResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/game.RoleListResponse";
        };

        return RoleListResponse;
    })();

    game.RoleItem = (function() {

        function RoleItem(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        RoleItem.prototype.gid = "";
        RoleItem.prototype.createTime = $util.Long ? $util.Long.fromBits(0,0,true) : 0;
        RoleItem.prototype.level = 0;
        RoleItem.prototype.name = "";
        RoleItem.prototype.teamIcon = 0;
        RoleItem.prototype.zoneId = "";
        RoleItem.prototype.zoneName = "";

        RoleItem.create = function create(properties) {
            return new RoleItem(properties);
        };

        RoleItem.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.gid != null && Object.hasOwnProperty.call(message, "gid"))
                writer.uint32(10).string(message.gid);
            if (message.createTime != null && Object.hasOwnProperty.call(message, "createTime"))
                writer.uint32(16).uint64(message.createTime);
            if (message.level != null && Object.hasOwnProperty.call(message, "level"))
                writer.uint32(24).uint32(message.level);
            if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                writer.uint32(34).string(message.name);
            if (message.teamIcon != null && Object.hasOwnProperty.call(message, "teamIcon"))
                writer.uint32(40).uint32(message.teamIcon);
            if (message.zoneId != null && Object.hasOwnProperty.call(message, "zoneId"))
                writer.uint32(50).string(message.zoneId);
            if (message.zoneName != null && Object.hasOwnProperty.call(message, "zoneName"))
                writer.uint32(58).string(message.zoneName);
            return writer;
        };

        RoleItem.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        RoleItem.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.game.RoleItem();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.gid = reader.string();
                        break;
                    }
                case 2: {
                        message.createTime = reader.uint64();
                        break;
                    }
                case 3: {
                        message.level = reader.uint32();
                        break;
                    }
                case 4: {
                        message.name = reader.string();
                        break;
                    }
                case 5: {
                        message.teamIcon = reader.uint32();
                        break;
                    }
                case 6: {
                        message.zoneId = reader.string();
                        break;
                    }
                case 7: {
                        message.zoneName = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        RoleItem.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        RoleItem.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.gid != null && message.hasOwnProperty("gid"))
                if (!$util.isString(message.gid))
                    return "gid: string expected";
            if (message.createTime != null && message.hasOwnProperty("createTime"))
                if (!$util.isInteger(message.createTime) && !(message.createTime && $util.isInteger(message.createTime.low) && $util.isInteger(message.createTime.high)))
                    return "createTime: integer|Long expected";
            if (message.level != null && message.hasOwnProperty("level"))
                if (!$util.isInteger(message.level))
                    return "level: integer expected";
            if (message.name != null && message.hasOwnProperty("name"))
                if (!$util.isString(message.name))
                    return "name: string expected";
            if (message.teamIcon != null && message.hasOwnProperty("teamIcon"))
                if (!$util.isInteger(message.teamIcon))
                    return "teamIcon: integer expected";
            if (message.zoneId != null && message.hasOwnProperty("zoneId"))
                if (!$util.isString(message.zoneId))
                    return "zoneId: string expected";
            if (message.zoneName != null && message.hasOwnProperty("zoneName"))
                if (!$util.isString(message.zoneName))
                    return "zoneName: string expected";
            return null;
        };

        RoleItem.fromObject = function fromObject(object) {
            if (object instanceof $root.game.RoleItem)
                return object;
            var message = new $root.game.RoleItem();
            if (object.gid != null)
                message.gid = String(object.gid);
            if (object.createTime != null)
                if ($util.Long)
                    (message.createTime = $util.Long.fromValue(object.createTime)).unsigned = true;
                else if (typeof object.createTime === "string")
                    message.createTime = parseInt(object.createTime, 10);
                else if (typeof object.createTime === "number")
                    message.createTime = object.createTime;
                else if (typeof object.createTime === "object")
                    message.createTime = new $util.LongBits(object.createTime.low >>> 0, object.createTime.high >>> 0).toNumber(true);
            if (object.level != null)
                message.level = object.level >>> 0;
            if (object.name != null)
                message.name = String(object.name);
            if (object.teamIcon != null)
                message.teamIcon = object.teamIcon >>> 0;
            if (object.zoneId != null)
                message.zoneId = String(object.zoneId);
            if (object.zoneName != null)
                message.zoneName = String(object.zoneName);
            return message;
        };

        RoleItem.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.gid = "";
                if ($util.Long) {
                    var long = new $util.Long(0, 0, true);
                    object.createTime = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.createTime = options.longs === String ? "0" : 0;
                object.level = 0;
                object.name = "";
                object.teamIcon = 0;
                object.zoneId = "";
                object.zoneName = "";
            }
            if (message.gid != null && message.hasOwnProperty("gid"))
                object.gid = message.gid;
            if (message.createTime != null && message.hasOwnProperty("createTime"))
                if (typeof message.createTime === "number")
                    object.createTime = options.longs === String ? String(message.createTime) : message.createTime;
                else
                    object.createTime = options.longs === String ? $util.Long.prototype.toString.call(message.createTime) : options.longs === Number ? new $util.LongBits(message.createTime.low >>> 0, message.createTime.high >>> 0).toNumber(true) : message.createTime;
            if (message.level != null && message.hasOwnProperty("level"))
                object.level = message.level;
            if (message.name != null && message.hasOwnProperty("name"))
                object.name = message.name;
            if (message.teamIcon != null && message.hasOwnProperty("teamIcon"))
                object.teamIcon = message.teamIcon;
            if (message.zoneId != null && message.hasOwnProperty("zoneId"))
                object.zoneId = message.zoneId;
            if (message.zoneName != null && message.hasOwnProperty("zoneName"))
                object.zoneName = message.zoneName;
            return object;
        };

        RoleItem.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        RoleItem.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/game.RoleItem";
        };

        return RoleItem;
    })();

    game.ProductListRequest = (function() {

        function ProductListRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        ProductListRequest.prototype.appId = "";
        ProductListRequest.prototype.page = null;
        ProductListRequest.prototype.keys = "";
        ProductListRequest.prototype.gid = "";
        ProductListRequest.prototype.currency = "";
        ProductListRequest.prototype.accessToken = "";

        ProductListRequest.create = function create(properties) {
            return new ProductListRequest(properties);
        };

        ProductListRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.appId != null && Object.hasOwnProperty.call(message, "appId"))
                writer.uint32(10).string(message.appId);
            if (message.page != null && Object.hasOwnProperty.call(message, "page"))
                $root.page.PagerRequest.encode(message.page, writer.uint32(18).fork()).ldelim();
            if (message.keys != null && Object.hasOwnProperty.call(message, "keys"))
                writer.uint32(26).string(message.keys);
            if (message.gid != null && Object.hasOwnProperty.call(message, "gid"))
                writer.uint32(34).string(message.gid);
            if (message.currency != null && Object.hasOwnProperty.call(message, "currency"))
                writer.uint32(42).string(message.currency);
            if (message.accessToken != null && Object.hasOwnProperty.call(message, "accessToken"))
                writer.uint32(50).string(message.accessToken);
            return writer;
        };

        ProductListRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        ProductListRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.game.ProductListRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.appId = reader.string();
                        break;
                    }
                case 2: {
                        message.page = $root.page.PagerRequest.decode(reader, reader.uint32());
                        break;
                    }
                case 3: {
                        message.keys = reader.string();
                        break;
                    }
                case 4: {
                        message.gid = reader.string();
                        break;
                    }
                case 5: {
                        message.currency = reader.string();
                        break;
                    }
                case 6: {
                        message.accessToken = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        ProductListRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        ProductListRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.appId != null && message.hasOwnProperty("appId"))
                if (!$util.isString(message.appId))
                    return "appId: string expected";
            if (message.page != null && message.hasOwnProperty("page")) {
                var error = $root.page.PagerRequest.verify(message.page);
                if (error)
                    return "page." + error;
            }
            if (message.keys != null && message.hasOwnProperty("keys"))
                if (!$util.isString(message.keys))
                    return "keys: string expected";
            if (message.gid != null && message.hasOwnProperty("gid"))
                if (!$util.isString(message.gid))
                    return "gid: string expected";
            if (message.currency != null && message.hasOwnProperty("currency"))
                if (!$util.isString(message.currency))
                    return "currency: string expected";
            if (message.accessToken != null && message.hasOwnProperty("accessToken"))
                if (!$util.isString(message.accessToken))
                    return "accessToken: string expected";
            return null;
        };

        ProductListRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.game.ProductListRequest)
                return object;
            var message = new $root.game.ProductListRequest();
            if (object.appId != null)
                message.appId = String(object.appId);
            if (object.page != null) {
                if (typeof object.page !== "object")
                    throw TypeError(".game.ProductListRequest.page: object expected");
                message.page = $root.page.PagerRequest.fromObject(object.page);
            }
            if (object.keys != null)
                message.keys = String(object.keys);
            if (object.gid != null)
                message.gid = String(object.gid);
            if (object.currency != null)
                message.currency = String(object.currency);
            if (object.accessToken != null)
                message.accessToken = String(object.accessToken);
            return message;
        };

        ProductListRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.appId = "";
                object.page = null;
                object.keys = "";
                object.gid = "";
                object.currency = "";
                object.accessToken = "";
            }
            if (message.appId != null && message.hasOwnProperty("appId"))
                object.appId = message.appId;
            if (message.page != null && message.hasOwnProperty("page"))
                object.page = $root.page.PagerRequest.toObject(message.page, options);
            if (message.keys != null && message.hasOwnProperty("keys"))
                object.keys = message.keys;
            if (message.gid != null && message.hasOwnProperty("gid"))
                object.gid = message.gid;
            if (message.currency != null && message.hasOwnProperty("currency"))
                object.currency = message.currency;
            if (message.accessToken != null && message.hasOwnProperty("accessToken"))
                object.accessToken = message.accessToken;
            return object;
        };

        ProductListRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        ProductListRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/game.ProductListRequest";
        };

        return ProductListRequest;
    })();

    game.ProductPageResponse = (function() {

        function ProductPageResponse(properties) {
            this.raws = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        ProductPageResponse.prototype.raws = $util.emptyArray;
        ProductPageResponse.prototype.page = null;

        ProductPageResponse.create = function create(properties) {
            return new ProductPageResponse(properties);
        };

        ProductPageResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.raws != null && message.raws.length)
                for (var i = 0; i < message.raws.length; ++i)
                    $root.game.ProductItem.encode(message.raws[i], writer.uint32(10).fork()).ldelim();
            if (message.page != null && Object.hasOwnProperty.call(message, "page"))
                $root.page.PagerResponse.encode(message.page, writer.uint32(18).fork()).ldelim();
            return writer;
        };

        ProductPageResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        ProductPageResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.game.ProductPageResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        if (!(message.raws && message.raws.length))
                            message.raws = [];
                        message.raws.push($root.game.ProductItem.decode(reader, reader.uint32()));
                        break;
                    }
                case 2: {
                        message.page = $root.page.PagerResponse.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        ProductPageResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        ProductPageResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.raws != null && message.hasOwnProperty("raws")) {
                if (!Array.isArray(message.raws))
                    return "raws: array expected";
                for (var i = 0; i < message.raws.length; ++i) {
                    var error = $root.game.ProductItem.verify(message.raws[i]);
                    if (error)
                        return "raws." + error;
                }
            }
            if (message.page != null && message.hasOwnProperty("page")) {
                var error = $root.page.PagerResponse.verify(message.page);
                if (error)
                    return "page." + error;
            }
            return null;
        };

        ProductPageResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.game.ProductPageResponse)
                return object;
            var message = new $root.game.ProductPageResponse();
            if (object.raws) {
                if (!Array.isArray(object.raws))
                    throw TypeError(".game.ProductPageResponse.raws: array expected");
                message.raws = [];
                for (var i = 0; i < object.raws.length; ++i) {
                    if (typeof object.raws[i] !== "object")
                        throw TypeError(".game.ProductPageResponse.raws: object expected");
                    message.raws[i] = $root.game.ProductItem.fromObject(object.raws[i]);
                }
            }
            if (object.page != null) {
                if (typeof object.page !== "object")
                    throw TypeError(".game.ProductPageResponse.page: object expected");
                message.page = $root.page.PagerResponse.fromObject(object.page);
            }
            return message;
        };

        ProductPageResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.arrays || options.defaults)
                object.raws = [];
            if (options.defaults)
                object.page = null;
            if (message.raws && message.raws.length) {
                object.raws = [];
                for (var j = 0; j < message.raws.length; ++j)
                    object.raws[j] = $root.game.ProductItem.toObject(message.raws[j], options);
            }
            if (message.page != null && message.hasOwnProperty("page"))
                object.page = $root.page.PagerResponse.toObject(message.page, options);
            return object;
        };

        ProductPageResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        ProductPageResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/game.ProductPageResponse";
        };

        return ProductPageResponse;
    })();

    game.ProductItem = (function() {

        function ProductItem(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        ProductItem.prototype.commodityType = 0;
        ProductItem.prototype.id = $util.Long ? $util.Long.fromBits(0,0,true) : 0;
        ProductItem.prototype.name = "";
        ProductItem.prototype.extraInfo = "";
        ProductItem.prototype.price = 0;
        ProductItem.prototype.currency = "";
        ProductItem.prototype.icon = "";

        ProductItem.create = function create(properties) {
            return new ProductItem(properties);
        };

        ProductItem.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.commodityType != null && Object.hasOwnProperty.call(message, "commodityType"))
                writer.uint32(8).uint32(message.commodityType);
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(16).uint64(message.id);
            if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                writer.uint32(26).string(message.name);
            if (message.extraInfo != null && Object.hasOwnProperty.call(message, "extraInfo"))
                writer.uint32(34).string(message.extraInfo);
            if (message.price != null && Object.hasOwnProperty.call(message, "price"))
                writer.uint32(40).uint32(message.price);
            if (message.currency != null && Object.hasOwnProperty.call(message, "currency"))
                writer.uint32(50).string(message.currency);
            if (message.icon != null && Object.hasOwnProperty.call(message, "icon"))
                writer.uint32(58).string(message.icon);
            return writer;
        };

        ProductItem.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        ProductItem.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.game.ProductItem();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.commodityType = reader.uint32();
                        break;
                    }
                case 2: {
                        message.id = reader.uint64();
                        break;
                    }
                case 3: {
                        message.name = reader.string();
                        break;
                    }
                case 4: {
                        message.extraInfo = reader.string();
                        break;
                    }
                case 5: {
                        message.price = reader.uint32();
                        break;
                    }
                case 6: {
                        message.currency = reader.string();
                        break;
                    }
                case 7: {
                        message.icon = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        ProductItem.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        ProductItem.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.commodityType != null && message.hasOwnProperty("commodityType"))
                if (!$util.isInteger(message.commodityType))
                    return "commodityType: integer expected";
            if (message.id != null && message.hasOwnProperty("id"))
                if (!$util.isInteger(message.id) && !(message.id && $util.isInteger(message.id.low) && $util.isInteger(message.id.high)))
                    return "id: integer|Long expected";
            if (message.name != null && message.hasOwnProperty("name"))
                if (!$util.isString(message.name))
                    return "name: string expected";
            if (message.extraInfo != null && message.hasOwnProperty("extraInfo"))
                if (!$util.isString(message.extraInfo))
                    return "extraInfo: string expected";
            if (message.price != null && message.hasOwnProperty("price"))
                if (!$util.isInteger(message.price))
                    return "price: integer expected";
            if (message.currency != null && message.hasOwnProperty("currency"))
                if (!$util.isString(message.currency))
                    return "currency: string expected";
            if (message.icon != null && message.hasOwnProperty("icon"))
                if (!$util.isString(message.icon))
                    return "icon: string expected";
            return null;
        };

        ProductItem.fromObject = function fromObject(object) {
            if (object instanceof $root.game.ProductItem)
                return object;
            var message = new $root.game.ProductItem();
            if (object.commodityType != null)
                message.commodityType = object.commodityType >>> 0;
            if (object.id != null)
                if ($util.Long)
                    (message.id = $util.Long.fromValue(object.id)).unsigned = true;
                else if (typeof object.id === "string")
                    message.id = parseInt(object.id, 10);
                else if (typeof object.id === "number")
                    message.id = object.id;
                else if (typeof object.id === "object")
                    message.id = new $util.LongBits(object.id.low >>> 0, object.id.high >>> 0).toNumber(true);
            if (object.name != null)
                message.name = String(object.name);
            if (object.extraInfo != null)
                message.extraInfo = String(object.extraInfo);
            if (object.price != null)
                message.price = object.price >>> 0;
            if (object.currency != null)
                message.currency = String(object.currency);
            if (object.icon != null)
                message.icon = String(object.icon);
            return message;
        };

        ProductItem.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.commodityType = 0;
                if ($util.Long) {
                    var long = new $util.Long(0, 0, true);
                    object.id = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.id = options.longs === String ? "0" : 0;
                object.name = "";
                object.extraInfo = "";
                object.price = 0;
                object.currency = "";
                object.icon = "";
            }
            if (message.commodityType != null && message.hasOwnProperty("commodityType"))
                object.commodityType = message.commodityType;
            if (message.id != null && message.hasOwnProperty("id"))
                if (typeof message.id === "number")
                    object.id = options.longs === String ? String(message.id) : message.id;
                else
                    object.id = options.longs === String ? $util.Long.prototype.toString.call(message.id) : options.longs === Number ? new $util.LongBits(message.id.low >>> 0, message.id.high >>> 0).toNumber(true) : message.id;
            if (message.name != null && message.hasOwnProperty("name"))
                object.name = message.name;
            if (message.extraInfo != null && message.hasOwnProperty("extraInfo"))
                object.extraInfo = message.extraInfo;
            if (message.price != null && message.hasOwnProperty("price"))
                object.price = message.price;
            if (message.currency != null && message.hasOwnProperty("currency"))
                object.currency = message.currency;
            if (message.icon != null && message.hasOwnProperty("icon"))
                object.icon = message.icon;
            return object;
        };

        ProductItem.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        ProductItem.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/game.ProductItem";
        };

        return ProductItem;
    })();

    game.OrderListRequest = (function() {

        function OrderListRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        OrderListRequest.prototype.appId = "";
        OrderListRequest.prototype.page = null;
        OrderListRequest.prototype.keys = "";
        OrderListRequest.prototype.serverId = "";
        OrderListRequest.prototype.currency = "";
        OrderListRequest.prototype.accessToken = "";

        OrderListRequest.create = function create(properties) {
            return new OrderListRequest(properties);
        };

        OrderListRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.appId != null && Object.hasOwnProperty.call(message, "appId"))
                writer.uint32(10).string(message.appId);
            if (message.page != null && Object.hasOwnProperty.call(message, "page"))
                $root.page.PagerRequest.encode(message.page, writer.uint32(18).fork()).ldelim();
            if (message.keys != null && Object.hasOwnProperty.call(message, "keys"))
                writer.uint32(26).string(message.keys);
            if (message.serverId != null && Object.hasOwnProperty.call(message, "serverId"))
                writer.uint32(34).string(message.serverId);
            if (message.currency != null && Object.hasOwnProperty.call(message, "currency"))
                writer.uint32(42).string(message.currency);
            if (message.accessToken != null && Object.hasOwnProperty.call(message, "accessToken"))
                writer.uint32(50).string(message.accessToken);
            return writer;
        };

        OrderListRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        OrderListRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.game.OrderListRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.appId = reader.string();
                        break;
                    }
                case 2: {
                        message.page = $root.page.PagerRequest.decode(reader, reader.uint32());
                        break;
                    }
                case 3: {
                        message.keys = reader.string();
                        break;
                    }
                case 4: {
                        message.serverId = reader.string();
                        break;
                    }
                case 5: {
                        message.currency = reader.string();
                        break;
                    }
                case 6: {
                        message.accessToken = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        OrderListRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        OrderListRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.appId != null && message.hasOwnProperty("appId"))
                if (!$util.isString(message.appId))
                    return "appId: string expected";
            if (message.page != null && message.hasOwnProperty("page")) {
                var error = $root.page.PagerRequest.verify(message.page);
                if (error)
                    return "page." + error;
            }
            if (message.keys != null && message.hasOwnProperty("keys"))
                if (!$util.isString(message.keys))
                    return "keys: string expected";
            if (message.serverId != null && message.hasOwnProperty("serverId"))
                if (!$util.isString(message.serverId))
                    return "serverId: string expected";
            if (message.currency != null && message.hasOwnProperty("currency"))
                if (!$util.isString(message.currency))
                    return "currency: string expected";
            if (message.accessToken != null && message.hasOwnProperty("accessToken"))
                if (!$util.isString(message.accessToken))
                    return "accessToken: string expected";
            return null;
        };

        OrderListRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.game.OrderListRequest)
                return object;
            var message = new $root.game.OrderListRequest();
            if (object.appId != null)
                message.appId = String(object.appId);
            if (object.page != null) {
                if (typeof object.page !== "object")
                    throw TypeError(".game.OrderListRequest.page: object expected");
                message.page = $root.page.PagerRequest.fromObject(object.page);
            }
            if (object.keys != null)
                message.keys = String(object.keys);
            if (object.serverId != null)
                message.serverId = String(object.serverId);
            if (object.currency != null)
                message.currency = String(object.currency);
            if (object.accessToken != null)
                message.accessToken = String(object.accessToken);
            return message;
        };

        OrderListRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.appId = "";
                object.page = null;
                object.keys = "";
                object.serverId = "";
                object.currency = "";
                object.accessToken = "";
            }
            if (message.appId != null && message.hasOwnProperty("appId"))
                object.appId = message.appId;
            if (message.page != null && message.hasOwnProperty("page"))
                object.page = $root.page.PagerRequest.toObject(message.page, options);
            if (message.keys != null && message.hasOwnProperty("keys"))
                object.keys = message.keys;
            if (message.serverId != null && message.hasOwnProperty("serverId"))
                object.serverId = message.serverId;
            if (message.currency != null && message.hasOwnProperty("currency"))
                object.currency = message.currency;
            if (message.accessToken != null && message.hasOwnProperty("accessToken"))
                object.accessToken = message.accessToken;
            return object;
        };

        OrderListRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        OrderListRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/game.OrderListRequest";
        };

        return OrderListRequest;
    })();

    game.OrderPageResponse = (function() {

        function OrderPageResponse(properties) {
            this.raws = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        OrderPageResponse.prototype.raws = $util.emptyArray;
        OrderPageResponse.prototype.page = null;

        OrderPageResponse.create = function create(properties) {
            return new OrderPageResponse(properties);
        };

        OrderPageResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.raws != null && message.raws.length)
                for (var i = 0; i < message.raws.length; ++i)
                    $root.game.OrderItem.encode(message.raws[i], writer.uint32(10).fork()).ldelim();
            if (message.page != null && Object.hasOwnProperty.call(message, "page"))
                $root.page.PagerResponse.encode(message.page, writer.uint32(18).fork()).ldelim();
            return writer;
        };

        OrderPageResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        OrderPageResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.game.OrderPageResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        if (!(message.raws && message.raws.length))
                            message.raws = [];
                        message.raws.push($root.game.OrderItem.decode(reader, reader.uint32()));
                        break;
                    }
                case 2: {
                        message.page = $root.page.PagerResponse.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        OrderPageResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        OrderPageResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.raws != null && message.hasOwnProperty("raws")) {
                if (!Array.isArray(message.raws))
                    return "raws: array expected";
                for (var i = 0; i < message.raws.length; ++i) {
                    var error = $root.game.OrderItem.verify(message.raws[i]);
                    if (error)
                        return "raws." + error;
                }
            }
            if (message.page != null && message.hasOwnProperty("page")) {
                var error = $root.page.PagerResponse.verify(message.page);
                if (error)
                    return "page." + error;
            }
            return null;
        };

        OrderPageResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.game.OrderPageResponse)
                return object;
            var message = new $root.game.OrderPageResponse();
            if (object.raws) {
                if (!Array.isArray(object.raws))
                    throw TypeError(".game.OrderPageResponse.raws: array expected");
                message.raws = [];
                for (var i = 0; i < object.raws.length; ++i) {
                    if (typeof object.raws[i] !== "object")
                        throw TypeError(".game.OrderPageResponse.raws: object expected");
                    message.raws[i] = $root.game.OrderItem.fromObject(object.raws[i]);
                }
            }
            if (object.page != null) {
                if (typeof object.page !== "object")
                    throw TypeError(".game.OrderPageResponse.page: object expected");
                message.page = $root.page.PagerResponse.fromObject(object.page);
            }
            return message;
        };

        OrderPageResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.arrays || options.defaults)
                object.raws = [];
            if (options.defaults)
                object.page = null;
            if (message.raws && message.raws.length) {
                object.raws = [];
                for (var j = 0; j < message.raws.length; ++j)
                    object.raws[j] = $root.game.OrderItem.toObject(message.raws[j], options);
            }
            if (message.page != null && message.hasOwnProperty("page"))
                object.page = $root.page.PagerResponse.toObject(message.page, options);
            return object;
        };

        OrderPageResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        OrderPageResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/game.OrderPageResponse";
        };

        return OrderPageResponse;
    })();

    game.OrderItem = (function() {

        function OrderItem(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        OrderItem.prototype.times = "";
        OrderItem.prototype.zoneName = "";
        OrderItem.prototype.payType = 0;
        OrderItem.prototype.productName = "";
        OrderItem.prototype.price = 0;
        OrderItem.prototype.rid = "";

        OrderItem.create = function create(properties) {
            return new OrderItem(properties);
        };

        OrderItem.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.times != null && Object.hasOwnProperty.call(message, "times"))
                writer.uint32(10).string(message.times);
            if (message.zoneName != null && Object.hasOwnProperty.call(message, "zoneName"))
                writer.uint32(18).string(message.zoneName);
            if (message.payType != null && Object.hasOwnProperty.call(message, "payType"))
                writer.uint32(24).uint32(message.payType);
            if (message.productName != null && Object.hasOwnProperty.call(message, "productName"))
                writer.uint32(34).string(message.productName);
            if (message.price != null && Object.hasOwnProperty.call(message, "price"))
                writer.uint32(40).uint32(message.price);
            if (message.rid != null && Object.hasOwnProperty.call(message, "rid"))
                writer.uint32(50).string(message.rid);
            return writer;
        };

        OrderItem.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        OrderItem.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.game.OrderItem();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.times = reader.string();
                        break;
                    }
                case 2: {
                        message.zoneName = reader.string();
                        break;
                    }
                case 3: {
                        message.payType = reader.uint32();
                        break;
                    }
                case 4: {
                        message.productName = reader.string();
                        break;
                    }
                case 5: {
                        message.price = reader.uint32();
                        break;
                    }
                case 6: {
                        message.rid = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        OrderItem.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        OrderItem.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.times != null && message.hasOwnProperty("times"))
                if (!$util.isString(message.times))
                    return "times: string expected";
            if (message.zoneName != null && message.hasOwnProperty("zoneName"))
                if (!$util.isString(message.zoneName))
                    return "zoneName: string expected";
            if (message.payType != null && message.hasOwnProperty("payType"))
                if (!$util.isInteger(message.payType))
                    return "payType: integer expected";
            if (message.productName != null && message.hasOwnProperty("productName"))
                if (!$util.isString(message.productName))
                    return "productName: string expected";
            if (message.price != null && message.hasOwnProperty("price"))
                if (!$util.isInteger(message.price))
                    return "price: integer expected";
            if (message.rid != null && message.hasOwnProperty("rid"))
                if (!$util.isString(message.rid))
                    return "rid: string expected";
            return null;
        };

        OrderItem.fromObject = function fromObject(object) {
            if (object instanceof $root.game.OrderItem)
                return object;
            var message = new $root.game.OrderItem();
            if (object.times != null)
                message.times = String(object.times);
            if (object.zoneName != null)
                message.zoneName = String(object.zoneName);
            if (object.payType != null)
                message.payType = object.payType >>> 0;
            if (object.productName != null)
                message.productName = String(object.productName);
            if (object.price != null)
                message.price = object.price >>> 0;
            if (object.rid != null)
                message.rid = String(object.rid);
            return message;
        };

        OrderItem.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.times = "";
                object.zoneName = "";
                object.payType = 0;
                object.productName = "";
                object.price = 0;
                object.rid = "";
            }
            if (message.times != null && message.hasOwnProperty("times"))
                object.times = message.times;
            if (message.zoneName != null && message.hasOwnProperty("zoneName"))
                object.zoneName = message.zoneName;
            if (message.payType != null && message.hasOwnProperty("payType"))
                object.payType = message.payType;
            if (message.productName != null && message.hasOwnProperty("productName"))
                object.productName = message.productName;
            if (message.price != null && message.hasOwnProperty("price"))
                object.price = message.price;
            if (message.rid != null && message.hasOwnProperty("rid"))
                object.rid = message.rid;
            return object;
        };

        OrderItem.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        OrderItem.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/game.OrderItem";
        };

        return OrderItem;
    })();

    return game;
})();

$root.page = (function() {

    var page = {};

    page.PagerRequest = (function() {

        function PagerRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        PagerRequest.prototype.page = 0;
        PagerRequest.prototype.pageSize = 0;

        PagerRequest.create = function create(properties) {
            return new PagerRequest(properties);
        };

        PagerRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.page != null && Object.hasOwnProperty.call(message, "page"))
                writer.uint32(8).uint32(message.page);
            if (message.pageSize != null && Object.hasOwnProperty.call(message, "pageSize"))
                writer.uint32(16).uint32(message.pageSize);
            return writer;
        };

        PagerRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        PagerRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.page.PagerRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.page = reader.uint32();
                        break;
                    }
                case 2: {
                        message.pageSize = reader.uint32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        PagerRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        PagerRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.page != null && message.hasOwnProperty("page"))
                if (!$util.isInteger(message.page))
                    return "page: integer expected";
            if (message.pageSize != null && message.hasOwnProperty("pageSize"))
                if (!$util.isInteger(message.pageSize))
                    return "pageSize: integer expected";
            return null;
        };

        PagerRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.page.PagerRequest)
                return object;
            var message = new $root.page.PagerRequest();
            if (object.page != null)
                message.page = object.page >>> 0;
            if (object.pageSize != null)
                message.pageSize = object.pageSize >>> 0;
            return message;
        };

        PagerRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.page = 0;
                object.pageSize = 0;
            }
            if (message.page != null && message.hasOwnProperty("page"))
                object.page = message.page;
            if (message.pageSize != null && message.hasOwnProperty("pageSize"))
                object.pageSize = message.pageSize;
            return object;
        };

        PagerRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        PagerRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/page.PagerRequest";
        };

        return PagerRequest;
    })();

    page.PagerResponse = (function() {

        function PagerResponse(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        PagerResponse.prototype.currentPage = 0;
        PagerResponse.prototype.pageSize = 0;
        PagerResponse.prototype.totalNum = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        PagerResponse.create = function create(properties) {
            return new PagerResponse(properties);
        };

        PagerResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.currentPage != null && Object.hasOwnProperty.call(message, "currentPage"))
                writer.uint32(8).uint32(message.currentPage);
            if (message.pageSize != null && Object.hasOwnProperty.call(message, "pageSize"))
                writer.uint32(16).uint32(message.pageSize);
            if (message.totalNum != null && Object.hasOwnProperty.call(message, "totalNum"))
                writer.uint32(24).uint64(message.totalNum);
            return writer;
        };

        PagerResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        PagerResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.page.PagerResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.currentPage = reader.uint32();
                        break;
                    }
                case 2: {
                        message.pageSize = reader.uint32();
                        break;
                    }
                case 3: {
                        message.totalNum = reader.uint64();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        PagerResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        PagerResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.currentPage != null && message.hasOwnProperty("currentPage"))
                if (!$util.isInteger(message.currentPage))
                    return "currentPage: integer expected";
            if (message.pageSize != null && message.hasOwnProperty("pageSize"))
                if (!$util.isInteger(message.pageSize))
                    return "pageSize: integer expected";
            if (message.totalNum != null && message.hasOwnProperty("totalNum"))
                if (!$util.isInteger(message.totalNum) && !(message.totalNum && $util.isInteger(message.totalNum.low) && $util.isInteger(message.totalNum.high)))
                    return "totalNum: integer|Long expected";
            return null;
        };

        PagerResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.page.PagerResponse)
                return object;
            var message = new $root.page.PagerResponse();
            if (object.currentPage != null)
                message.currentPage = object.currentPage >>> 0;
            if (object.pageSize != null)
                message.pageSize = object.pageSize >>> 0;
            if (object.totalNum != null)
                if ($util.Long)
                    (message.totalNum = $util.Long.fromValue(object.totalNum)).unsigned = true;
                else if (typeof object.totalNum === "string")
                    message.totalNum = parseInt(object.totalNum, 10);
                else if (typeof object.totalNum === "number")
                    message.totalNum = object.totalNum;
                else if (typeof object.totalNum === "object")
                    message.totalNum = new $util.LongBits(object.totalNum.low >>> 0, object.totalNum.high >>> 0).toNumber(true);
            return message;
        };

        PagerResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.currentPage = 0;
                object.pageSize = 0;
                if ($util.Long) {
                    var long = new $util.Long(0, 0, true);
                    object.totalNum = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.totalNum = options.longs === String ? "0" : 0;
            }
            if (message.currentPage != null && message.hasOwnProperty("currentPage"))
                object.currentPage = message.currentPage;
            if (message.pageSize != null && message.hasOwnProperty("pageSize"))
                object.pageSize = message.pageSize;
            if (message.totalNum != null && message.hasOwnProperty("totalNum"))
                if (typeof message.totalNum === "number")
                    object.totalNum = options.longs === String ? String(message.totalNum) : message.totalNum;
                else
                    object.totalNum = options.longs === String ? $util.Long.prototype.toString.call(message.totalNum) : options.longs === Number ? new $util.LongBits(message.totalNum.low >>> 0, message.totalNum.high >>> 0).toNumber(true) : message.totalNum;
            return object;
        };

        PagerResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        PagerResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/page.PagerResponse";
        };

        return PagerResponse;
    })();

    return page;
})();

$root.mycard = (function() {

    var mycard = {};

    mycard.MyCardCreateOrderRequest = (function() {

        function MyCardCreateOrderRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        MyCardCreateOrderRequest.prototype.appId = "";
        MyCardCreateOrderRequest.prototype.channelId = "";
        MyCardCreateOrderRequest.prototype.deviceId = "";
        MyCardCreateOrderRequest.prototype.cpOrderId = "";
        MyCardCreateOrderRequest.prototype.extra = "";
        MyCardCreateOrderRequest.prototype.price = 0;
        MyCardCreateOrderRequest.prototype.currency = "";
        MyCardCreateOrderRequest.prototype.productId = "";
        MyCardCreateOrderRequest.prototype.productName = "";
        MyCardCreateOrderRequest.prototype.productDesc = "";
        MyCardCreateOrderRequest.prototype.roleId = "";
        MyCardCreateOrderRequest.prototype.roleName = "";
        MyCardCreateOrderRequest.prototype.roleLevel = "";
        MyCardCreateOrderRequest.prototype.serverId = "";
        MyCardCreateOrderRequest.prototype.serverName = "";
        MyCardCreateOrderRequest.prototype.accessToken = "";
        MyCardCreateOrderRequest.prototype.area = "";

        MyCardCreateOrderRequest.create = function create(properties) {
            return new MyCardCreateOrderRequest(properties);
        };

        MyCardCreateOrderRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.appId != null && Object.hasOwnProperty.call(message, "appId"))
                writer.uint32(10).string(message.appId);
            if (message.channelId != null && Object.hasOwnProperty.call(message, "channelId"))
                writer.uint32(18).string(message.channelId);
            if (message.deviceId != null && Object.hasOwnProperty.call(message, "deviceId"))
                writer.uint32(26).string(message.deviceId);
            if (message.cpOrderId != null && Object.hasOwnProperty.call(message, "cpOrderId"))
                writer.uint32(34).string(message.cpOrderId);
            if (message.extra != null && Object.hasOwnProperty.call(message, "extra"))
                writer.uint32(42).string(message.extra);
            if (message.price != null && Object.hasOwnProperty.call(message, "price"))
                writer.uint32(48).uint32(message.price);
            if (message.currency != null && Object.hasOwnProperty.call(message, "currency"))
                writer.uint32(58).string(message.currency);
            if (message.productId != null && Object.hasOwnProperty.call(message, "productId"))
                writer.uint32(66).string(message.productId);
            if (message.productName != null && Object.hasOwnProperty.call(message, "productName"))
                writer.uint32(74).string(message.productName);
            if (message.productDesc != null && Object.hasOwnProperty.call(message, "productDesc"))
                writer.uint32(82).string(message.productDesc);
            if (message.roleId != null && Object.hasOwnProperty.call(message, "roleId"))
                writer.uint32(90).string(message.roleId);
            if (message.roleName != null && Object.hasOwnProperty.call(message, "roleName"))
                writer.uint32(98).string(message.roleName);
            if (message.roleLevel != null && Object.hasOwnProperty.call(message, "roleLevel"))
                writer.uint32(106).string(message.roleLevel);
            if (message.serverId != null && Object.hasOwnProperty.call(message, "serverId"))
                writer.uint32(114).string(message.serverId);
            if (message.serverName != null && Object.hasOwnProperty.call(message, "serverName"))
                writer.uint32(122).string(message.serverName);
            if (message.accessToken != null && Object.hasOwnProperty.call(message, "accessToken"))
                writer.uint32(130).string(message.accessToken);
            if (message.area != null && Object.hasOwnProperty.call(message, "area"))
                writer.uint32(138).string(message.area);
            return writer;
        };

        MyCardCreateOrderRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        MyCardCreateOrderRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.mycard.MyCardCreateOrderRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.appId = reader.string();
                        break;
                    }
                case 2: {
                        message.channelId = reader.string();
                        break;
                    }
                case 3: {
                        message.deviceId = reader.string();
                        break;
                    }
                case 4: {
                        message.cpOrderId = reader.string();
                        break;
                    }
                case 5: {
                        message.extra = reader.string();
                        break;
                    }
                case 6: {
                        message.price = reader.uint32();
                        break;
                    }
                case 7: {
                        message.currency = reader.string();
                        break;
                    }
                case 8: {
                        message.productId = reader.string();
                        break;
                    }
                case 9: {
                        message.productName = reader.string();
                        break;
                    }
                case 10: {
                        message.productDesc = reader.string();
                        break;
                    }
                case 11: {
                        message.roleId = reader.string();
                        break;
                    }
                case 12: {
                        message.roleName = reader.string();
                        break;
                    }
                case 13: {
                        message.roleLevel = reader.string();
                        break;
                    }
                case 14: {
                        message.serverId = reader.string();
                        break;
                    }
                case 15: {
                        message.serverName = reader.string();
                        break;
                    }
                case 16: {
                        message.accessToken = reader.string();
                        break;
                    }
                case 17: {
                        message.area = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        MyCardCreateOrderRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        MyCardCreateOrderRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.appId != null && message.hasOwnProperty("appId"))
                if (!$util.isString(message.appId))
                    return "appId: string expected";
            if (message.channelId != null && message.hasOwnProperty("channelId"))
                if (!$util.isString(message.channelId))
                    return "channelId: string expected";
            if (message.deviceId != null && message.hasOwnProperty("deviceId"))
                if (!$util.isString(message.deviceId))
                    return "deviceId: string expected";
            if (message.cpOrderId != null && message.hasOwnProperty("cpOrderId"))
                if (!$util.isString(message.cpOrderId))
                    return "cpOrderId: string expected";
            if (message.extra != null && message.hasOwnProperty("extra"))
                if (!$util.isString(message.extra))
                    return "extra: string expected";
            if (message.price != null && message.hasOwnProperty("price"))
                if (!$util.isInteger(message.price))
                    return "price: integer expected";
            if (message.currency != null && message.hasOwnProperty("currency"))
                if (!$util.isString(message.currency))
                    return "currency: string expected";
            if (message.productId != null && message.hasOwnProperty("productId"))
                if (!$util.isString(message.productId))
                    return "productId: string expected";
            if (message.productName != null && message.hasOwnProperty("productName"))
                if (!$util.isString(message.productName))
                    return "productName: string expected";
            if (message.productDesc != null && message.hasOwnProperty("productDesc"))
                if (!$util.isString(message.productDesc))
                    return "productDesc: string expected";
            if (message.roleId != null && message.hasOwnProperty("roleId"))
                if (!$util.isString(message.roleId))
                    return "roleId: string expected";
            if (message.roleName != null && message.hasOwnProperty("roleName"))
                if (!$util.isString(message.roleName))
                    return "roleName: string expected";
            if (message.roleLevel != null && message.hasOwnProperty("roleLevel"))
                if (!$util.isString(message.roleLevel))
                    return "roleLevel: string expected";
            if (message.serverId != null && message.hasOwnProperty("serverId"))
                if (!$util.isString(message.serverId))
                    return "serverId: string expected";
            if (message.serverName != null && message.hasOwnProperty("serverName"))
                if (!$util.isString(message.serverName))
                    return "serverName: string expected";
            if (message.accessToken != null && message.hasOwnProperty("accessToken"))
                if (!$util.isString(message.accessToken))
                    return "accessToken: string expected";
            if (message.area != null && message.hasOwnProperty("area"))
                if (!$util.isString(message.area))
                    return "area: string expected";
            return null;
        };

        MyCardCreateOrderRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.mycard.MyCardCreateOrderRequest)
                return object;
            var message = new $root.mycard.MyCardCreateOrderRequest();
            if (object.appId != null)
                message.appId = String(object.appId);
            if (object.channelId != null)
                message.channelId = String(object.channelId);
            if (object.deviceId != null)
                message.deviceId = String(object.deviceId);
            if (object.cpOrderId != null)
                message.cpOrderId = String(object.cpOrderId);
            if (object.extra != null)
                message.extra = String(object.extra);
            if (object.price != null)
                message.price = object.price >>> 0;
            if (object.currency != null)
                message.currency = String(object.currency);
            if (object.productId != null)
                message.productId = String(object.productId);
            if (object.productName != null)
                message.productName = String(object.productName);
            if (object.productDesc != null)
                message.productDesc = String(object.productDesc);
            if (object.roleId != null)
                message.roleId = String(object.roleId);
            if (object.roleName != null)
                message.roleName = String(object.roleName);
            if (object.roleLevel != null)
                message.roleLevel = String(object.roleLevel);
            if (object.serverId != null)
                message.serverId = String(object.serverId);
            if (object.serverName != null)
                message.serverName = String(object.serverName);
            if (object.accessToken != null)
                message.accessToken = String(object.accessToken);
            if (object.area != null)
                message.area = String(object.area);
            return message;
        };

        MyCardCreateOrderRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.appId = "";
                object.channelId = "";
                object.deviceId = "";
                object.cpOrderId = "";
                object.extra = "";
                object.price = 0;
                object.currency = "";
                object.productId = "";
                object.productName = "";
                object.productDesc = "";
                object.roleId = "";
                object.roleName = "";
                object.roleLevel = "";
                object.serverId = "";
                object.serverName = "";
                object.accessToken = "";
                object.area = "";
            }
            if (message.appId != null && message.hasOwnProperty("appId"))
                object.appId = message.appId;
            if (message.channelId != null && message.hasOwnProperty("channelId"))
                object.channelId = message.channelId;
            if (message.deviceId != null && message.hasOwnProperty("deviceId"))
                object.deviceId = message.deviceId;
            if (message.cpOrderId != null && message.hasOwnProperty("cpOrderId"))
                object.cpOrderId = message.cpOrderId;
            if (message.extra != null && message.hasOwnProperty("extra"))
                object.extra = message.extra;
            if (message.price != null && message.hasOwnProperty("price"))
                object.price = message.price;
            if (message.currency != null && message.hasOwnProperty("currency"))
                object.currency = message.currency;
            if (message.productId != null && message.hasOwnProperty("productId"))
                object.productId = message.productId;
            if (message.productName != null && message.hasOwnProperty("productName"))
                object.productName = message.productName;
            if (message.productDesc != null && message.hasOwnProperty("productDesc"))
                object.productDesc = message.productDesc;
            if (message.roleId != null && message.hasOwnProperty("roleId"))
                object.roleId = message.roleId;
            if (message.roleName != null && message.hasOwnProperty("roleName"))
                object.roleName = message.roleName;
            if (message.roleLevel != null && message.hasOwnProperty("roleLevel"))
                object.roleLevel = message.roleLevel;
            if (message.serverId != null && message.hasOwnProperty("serverId"))
                object.serverId = message.serverId;
            if (message.serverName != null && message.hasOwnProperty("serverName"))
                object.serverName = message.serverName;
            if (message.accessToken != null && message.hasOwnProperty("accessToken"))
                object.accessToken = message.accessToken;
            if (message.area != null && message.hasOwnProperty("area"))
                object.area = message.area;
            return object;
        };

        MyCardCreateOrderRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        MyCardCreateOrderRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/mycard.MyCardCreateOrderRequest";
        };

        return MyCardCreateOrderRequest;
    })();

    mycard.MyCardCreateOrderResponse = (function() {

        function MyCardCreateOrderResponse(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        MyCardCreateOrderResponse.prototype.orderId = "";
        MyCardCreateOrderResponse.prototype.productId = "";
        MyCardCreateOrderResponse.prototype.transactionUrl = "";

        MyCardCreateOrderResponse.create = function create(properties) {
            return new MyCardCreateOrderResponse(properties);
        };

        MyCardCreateOrderResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.orderId != null && Object.hasOwnProperty.call(message, "orderId"))
                writer.uint32(10).string(message.orderId);
            if (message.productId != null && Object.hasOwnProperty.call(message, "productId"))
                writer.uint32(18).string(message.productId);
            if (message.transactionUrl != null && Object.hasOwnProperty.call(message, "transactionUrl"))
                writer.uint32(26).string(message.transactionUrl);
            return writer;
        };

        MyCardCreateOrderResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        MyCardCreateOrderResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.mycard.MyCardCreateOrderResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.orderId = reader.string();
                        break;
                    }
                case 2: {
                        message.productId = reader.string();
                        break;
                    }
                case 3: {
                        message.transactionUrl = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        MyCardCreateOrderResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        MyCardCreateOrderResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.orderId != null && message.hasOwnProperty("orderId"))
                if (!$util.isString(message.orderId))
                    return "orderId: string expected";
            if (message.productId != null && message.hasOwnProperty("productId"))
                if (!$util.isString(message.productId))
                    return "productId: string expected";
            if (message.transactionUrl != null && message.hasOwnProperty("transactionUrl"))
                if (!$util.isString(message.transactionUrl))
                    return "transactionUrl: string expected";
            return null;
        };

        MyCardCreateOrderResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.mycard.MyCardCreateOrderResponse)
                return object;
            var message = new $root.mycard.MyCardCreateOrderResponse();
            if (object.orderId != null)
                message.orderId = String(object.orderId);
            if (object.productId != null)
                message.productId = String(object.productId);
            if (object.transactionUrl != null)
                message.transactionUrl = String(object.transactionUrl);
            return message;
        };

        MyCardCreateOrderResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.orderId = "";
                object.productId = "";
                object.transactionUrl = "";
            }
            if (message.orderId != null && message.hasOwnProperty("orderId"))
                object.orderId = message.orderId;
            if (message.productId != null && message.hasOwnProperty("productId"))
                object.productId = message.productId;
            if (message.transactionUrl != null && message.hasOwnProperty("transactionUrl"))
                object.transactionUrl = message.transactionUrl;
            return object;
        };

        MyCardCreateOrderResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        MyCardCreateOrderResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/mycard.MyCardCreateOrderResponse";
        };

        return MyCardCreateOrderResponse;
    })();

    mycard.CheckOrderStateRequest = (function() {

        function CheckOrderStateRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        CheckOrderStateRequest.prototype.orderId = "";
        CheckOrderStateRequest.prototype.accessToken = "";

        CheckOrderStateRequest.create = function create(properties) {
            return new CheckOrderStateRequest(properties);
        };

        CheckOrderStateRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.orderId != null && Object.hasOwnProperty.call(message, "orderId"))
                writer.uint32(10).string(message.orderId);
            if (message.accessToken != null && Object.hasOwnProperty.call(message, "accessToken"))
                writer.uint32(18).string(message.accessToken);
            return writer;
        };

        CheckOrderStateRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        CheckOrderStateRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.mycard.CheckOrderStateRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.orderId = reader.string();
                        break;
                    }
                case 2: {
                        message.accessToken = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        CheckOrderStateRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        CheckOrderStateRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.orderId != null && message.hasOwnProperty("orderId"))
                if (!$util.isString(message.orderId))
                    return "orderId: string expected";
            if (message.accessToken != null && message.hasOwnProperty("accessToken"))
                if (!$util.isString(message.accessToken))
                    return "accessToken: string expected";
            return null;
        };

        CheckOrderStateRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.mycard.CheckOrderStateRequest)
                return object;
            var message = new $root.mycard.CheckOrderStateRequest();
            if (object.orderId != null)
                message.orderId = String(object.orderId);
            if (object.accessToken != null)
                message.accessToken = String(object.accessToken);
            return message;
        };

        CheckOrderStateRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.orderId = "";
                object.accessToken = "";
            }
            if (message.orderId != null && message.hasOwnProperty("orderId"))
                object.orderId = message.orderId;
            if (message.accessToken != null && message.hasOwnProperty("accessToken"))
                object.accessToken = message.accessToken;
            return object;
        };

        CheckOrderStateRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        CheckOrderStateRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/mycard.CheckOrderStateRequest";
        };

        return CheckOrderStateRequest;
    })();

    mycard.CheckOrderStateResponse = (function() {

        function CheckOrderStateResponse(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        CheckOrderStateResponse.prototype.orderId = "";
        CheckOrderStateResponse.prototype.state = 0;

        CheckOrderStateResponse.create = function create(properties) {
            return new CheckOrderStateResponse(properties);
        };

        CheckOrderStateResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.orderId != null && Object.hasOwnProperty.call(message, "orderId"))
                writer.uint32(10).string(message.orderId);
            if (message.state != null && Object.hasOwnProperty.call(message, "state"))
                writer.uint32(16).int32(message.state);
            return writer;
        };

        CheckOrderStateResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        CheckOrderStateResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.mycard.CheckOrderStateResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.orderId = reader.string();
                        break;
                    }
                case 2: {
                        message.state = reader.int32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        CheckOrderStateResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        CheckOrderStateResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.orderId != null && message.hasOwnProperty("orderId"))
                if (!$util.isString(message.orderId))
                    return "orderId: string expected";
            if (message.state != null && message.hasOwnProperty("state"))
                switch (message.state) {
                default:
                    return "state: enum value expected";
                case 0:
                case 1:
                case 2:
                case 3:
                    break;
                }
            return null;
        };

        CheckOrderStateResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.mycard.CheckOrderStateResponse)
                return object;
            var message = new $root.mycard.CheckOrderStateResponse();
            if (object.orderId != null)
                message.orderId = String(object.orderId);
            switch (object.state) {
            default:
                if (typeof object.state === "number") {
                    message.state = object.state;
                    break;
                }
                break;
            case "_OrderState_UNSPECIFIED":
            case 0:
                message.state = 0;
                break;
            case "OrderState_CREATED":
            case 1:
                message.state = 1;
                break;
            case "OrderState_HASH_COMMIT":
            case 2:
                message.state = 2;
                break;
            case "OrderState_DONE":
            case 3:
                message.state = 3;
                break;
            }
            return message;
        };

        CheckOrderStateResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.orderId = "";
                object.state = options.enums === String ? "_OrderState_UNSPECIFIED" : 0;
            }
            if (message.orderId != null && message.hasOwnProperty("orderId"))
                object.orderId = message.orderId;
            if (message.state != null && message.hasOwnProperty("state"))
                object.state = options.enums === String ? $root.enums.OrderState[message.state] === undefined ? message.state : $root.enums.OrderState[message.state] : message.state;
            return object;
        };

        CheckOrderStateResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        CheckOrderStateResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/mycard.CheckOrderStateResponse";
        };

        return CheckOrderStateResponse;
    })();

    return mycard;
})();

$root.passport = (function() {

    var passport = {};

    passport.OfficialLoginRequest = (function() {

        function OfficialLoginRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        OfficialLoginRequest.prototype.appId = "";
        OfficialLoginRequest.prototype.phonePrefix = "";
        OfficialLoginRequest.prototype.phone = "";
        OfficialLoginRequest.prototype.password = "";

        OfficialLoginRequest.create = function create(properties) {
            return new OfficialLoginRequest(properties);
        };

        OfficialLoginRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.appId != null && Object.hasOwnProperty.call(message, "appId"))
                writer.uint32(10).string(message.appId);
            if (message.phonePrefix != null && Object.hasOwnProperty.call(message, "phonePrefix"))
                writer.uint32(18).string(message.phonePrefix);
            if (message.phone != null && Object.hasOwnProperty.call(message, "phone"))
                writer.uint32(26).string(message.phone);
            if (message.password != null && Object.hasOwnProperty.call(message, "password"))
                writer.uint32(34).string(message.password);
            return writer;
        };

        OfficialLoginRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        OfficialLoginRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.passport.OfficialLoginRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.appId = reader.string();
                        break;
                    }
                case 2: {
                        message.phonePrefix = reader.string();
                        break;
                    }
                case 3: {
                        message.phone = reader.string();
                        break;
                    }
                case 4: {
                        message.password = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        OfficialLoginRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        OfficialLoginRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.appId != null && message.hasOwnProperty("appId"))
                if (!$util.isString(message.appId))
                    return "appId: string expected";
            if (message.phonePrefix != null && message.hasOwnProperty("phonePrefix"))
                if (!$util.isString(message.phonePrefix))
                    return "phonePrefix: string expected";
            if (message.phone != null && message.hasOwnProperty("phone"))
                if (!$util.isString(message.phone))
                    return "phone: string expected";
            if (message.password != null && message.hasOwnProperty("password"))
                if (!$util.isString(message.password))
                    return "password: string expected";
            return null;
        };

        OfficialLoginRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.passport.OfficialLoginRequest)
                return object;
            var message = new $root.passport.OfficialLoginRequest();
            if (object.appId != null)
                message.appId = String(object.appId);
            if (object.phonePrefix != null)
                message.phonePrefix = String(object.phonePrefix);
            if (object.phone != null)
                message.phone = String(object.phone);
            if (object.password != null)
                message.password = String(object.password);
            return message;
        };

        OfficialLoginRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.appId = "";
                object.phonePrefix = "";
                object.phone = "";
                object.password = "";
            }
            if (message.appId != null && message.hasOwnProperty("appId"))
                object.appId = message.appId;
            if (message.phonePrefix != null && message.hasOwnProperty("phonePrefix"))
                object.phonePrefix = message.phonePrefix;
            if (message.phone != null && message.hasOwnProperty("phone"))
                object.phone = message.phone;
            if (message.password != null && message.hasOwnProperty("password"))
                object.password = message.password;
            return object;
        };

        OfficialLoginRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        OfficialLoginRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/passport.OfficialLoginRequest";
        };

        return OfficialLoginRequest;
    })();

    passport.ThirdLoginRequest = (function() {

        function ThirdLoginRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        ThirdLoginRequest.prototype.appId = "";
        ThirdLoginRequest.prototype.account = "";
        ThirdLoginRequest.prototype.loginType = 0;

        ThirdLoginRequest.create = function create(properties) {
            return new ThirdLoginRequest(properties);
        };

        ThirdLoginRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.appId != null && Object.hasOwnProperty.call(message, "appId"))
                writer.uint32(10).string(message.appId);
            if (message.account != null && Object.hasOwnProperty.call(message, "account"))
                writer.uint32(18).string(message.account);
            if (message.loginType != null && Object.hasOwnProperty.call(message, "loginType"))
                writer.uint32(24).int32(message.loginType);
            return writer;
        };

        ThirdLoginRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        ThirdLoginRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.passport.ThirdLoginRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.appId = reader.string();
                        break;
                    }
                case 2: {
                        message.account = reader.string();
                        break;
                    }
                case 3: {
                        message.loginType = reader.int32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        ThirdLoginRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        ThirdLoginRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.appId != null && message.hasOwnProperty("appId"))
                if (!$util.isString(message.appId))
                    return "appId: string expected";
            if (message.account != null && message.hasOwnProperty("account"))
                if (!$util.isString(message.account))
                    return "account: string expected";
            if (message.loginType != null && message.hasOwnProperty("loginType"))
                switch (message.loginType) {
                default:
                    return "loginType: enum value expected";
                case 0:
                case 1:
                case 2:
                case 3:
                    break;
                }
            return null;
        };

        ThirdLoginRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.passport.ThirdLoginRequest)
                return object;
            var message = new $root.passport.ThirdLoginRequest();
            if (object.appId != null)
                message.appId = String(object.appId);
            if (object.account != null)
                message.account = String(object.account);
            switch (object.loginType) {
            default:
                if (typeof object.loginType === "number") {
                    message.loginType = object.loginType;
                    break;
                }
                break;
            case "_LoginType_UNSPECIFIED":
            case 0:
                message.loginType = 0;
                break;
            case "LOGIN_BY_GOOGLE":
            case 1:
                message.loginType = 1;
                break;
            case "LOGIN_BY_FB":
            case 2:
                message.loginType = 2;
                break;
            case "LOGIN_BY_APPLE":
            case 3:
                message.loginType = 3;
                break;
            }
            return message;
        };

        ThirdLoginRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.appId = "";
                object.account = "";
                object.loginType = options.enums === String ? "_LoginType_UNSPECIFIED" : 0;
            }
            if (message.appId != null && message.hasOwnProperty("appId"))
                object.appId = message.appId;
            if (message.account != null && message.hasOwnProperty("account"))
                object.account = message.account;
            if (message.loginType != null && message.hasOwnProperty("loginType"))
                object.loginType = options.enums === String ? $root.enums.LoginType[message.loginType] === undefined ? message.loginType : $root.enums.LoginType[message.loginType] : message.loginType;
            return object;
        };

        ThirdLoginRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        ThirdLoginRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/passport.ThirdLoginRequest";
        };

        return ThirdLoginRequest;
    })();

    passport.LoginResponse = (function() {

        function LoginResponse(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        LoginResponse.prototype.refreshToken = "";
        LoginResponse.prototype.accessToken = "";
        LoginResponse.prototype.name = "";

        LoginResponse.create = function create(properties) {
            return new LoginResponse(properties);
        };

        LoginResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.refreshToken != null && Object.hasOwnProperty.call(message, "refreshToken"))
                writer.uint32(10).string(message.refreshToken);
            if (message.accessToken != null && Object.hasOwnProperty.call(message, "accessToken"))
                writer.uint32(18).string(message.accessToken);
            if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                writer.uint32(26).string(message.name);
            return writer;
        };

        LoginResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        LoginResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.passport.LoginResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.refreshToken = reader.string();
                        break;
                    }
                case 2: {
                        message.accessToken = reader.string();
                        break;
                    }
                case 3: {
                        message.name = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        LoginResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        LoginResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.refreshToken != null && message.hasOwnProperty("refreshToken"))
                if (!$util.isString(message.refreshToken))
                    return "refreshToken: string expected";
            if (message.accessToken != null && message.hasOwnProperty("accessToken"))
                if (!$util.isString(message.accessToken))
                    return "accessToken: string expected";
            if (message.name != null && message.hasOwnProperty("name"))
                if (!$util.isString(message.name))
                    return "name: string expected";
            return null;
        };

        LoginResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.passport.LoginResponse)
                return object;
            var message = new $root.passport.LoginResponse();
            if (object.refreshToken != null)
                message.refreshToken = String(object.refreshToken);
            if (object.accessToken != null)
                message.accessToken = String(object.accessToken);
            if (object.name != null)
                message.name = String(object.name);
            return message;
        };

        LoginResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.refreshToken = "";
                object.accessToken = "";
                object.name = "";
            }
            if (message.refreshToken != null && message.hasOwnProperty("refreshToken"))
                object.refreshToken = message.refreshToken;
            if (message.accessToken != null && message.hasOwnProperty("accessToken"))
                object.accessToken = message.accessToken;
            if (message.name != null && message.hasOwnProperty("name"))
                object.name = message.name;
            return object;
        };

        LoginResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        LoginResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/passport.LoginResponse";
        };

        return LoginResponse;
    })();

    passport.RefreshTokenRequest = (function() {

        function RefreshTokenRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        RefreshTokenRequest.prototype.appId = "";
        RefreshTokenRequest.prototype.refreshToken = "";
        RefreshTokenRequest.prototype.accessToken = "";

        RefreshTokenRequest.create = function create(properties) {
            return new RefreshTokenRequest(properties);
        };

        RefreshTokenRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.appId != null && Object.hasOwnProperty.call(message, "appId"))
                writer.uint32(10).string(message.appId);
            if (message.refreshToken != null && Object.hasOwnProperty.call(message, "refreshToken"))
                writer.uint32(18).string(message.refreshToken);
            if (message.accessToken != null && Object.hasOwnProperty.call(message, "accessToken"))
                writer.uint32(26).string(message.accessToken);
            return writer;
        };

        RefreshTokenRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        RefreshTokenRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.passport.RefreshTokenRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.appId = reader.string();
                        break;
                    }
                case 2: {
                        message.refreshToken = reader.string();
                        break;
                    }
                case 3: {
                        message.accessToken = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        RefreshTokenRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        RefreshTokenRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.appId != null && message.hasOwnProperty("appId"))
                if (!$util.isString(message.appId))
                    return "appId: string expected";
            if (message.refreshToken != null && message.hasOwnProperty("refreshToken"))
                if (!$util.isString(message.refreshToken))
                    return "refreshToken: string expected";
            if (message.accessToken != null && message.hasOwnProperty("accessToken"))
                if (!$util.isString(message.accessToken))
                    return "accessToken: string expected";
            return null;
        };

        RefreshTokenRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.passport.RefreshTokenRequest)
                return object;
            var message = new $root.passport.RefreshTokenRequest();
            if (object.appId != null)
                message.appId = String(object.appId);
            if (object.refreshToken != null)
                message.refreshToken = String(object.refreshToken);
            if (object.accessToken != null)
                message.accessToken = String(object.accessToken);
            return message;
        };

        RefreshTokenRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.appId = "";
                object.refreshToken = "";
                object.accessToken = "";
            }
            if (message.appId != null && message.hasOwnProperty("appId"))
                object.appId = message.appId;
            if (message.refreshToken != null && message.hasOwnProperty("refreshToken"))
                object.refreshToken = message.refreshToken;
            if (message.accessToken != null && message.hasOwnProperty("accessToken"))
                object.accessToken = message.accessToken;
            return object;
        };

        RefreshTokenRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        RefreshTokenRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/passport.RefreshTokenRequest";
        };

        return RefreshTokenRequest;
    })();

    return passport;
})();

$root.payload = (function() {

    var payload = {};

    payload.TimeRange = (function() {

        function TimeRange(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        TimeRange.prototype.start = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
        TimeRange.prototype.end = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        TimeRange.create = function create(properties) {
            return new TimeRange(properties);
        };

        TimeRange.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.start != null && Object.hasOwnProperty.call(message, "start"))
                writer.uint32(8).int64(message.start);
            if (message.end != null && Object.hasOwnProperty.call(message, "end"))
                writer.uint32(16).int64(message.end);
            return writer;
        };

        TimeRange.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        TimeRange.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.payload.TimeRange();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.start = reader.int64();
                        break;
                    }
                case 2: {
                        message.end = reader.int64();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        TimeRange.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        TimeRange.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.start != null && message.hasOwnProperty("start"))
                if (!$util.isInteger(message.start) && !(message.start && $util.isInteger(message.start.low) && $util.isInteger(message.start.high)))
                    return "start: integer|Long expected";
            if (message.end != null && message.hasOwnProperty("end"))
                if (!$util.isInteger(message.end) && !(message.end && $util.isInteger(message.end.low) && $util.isInteger(message.end.high)))
                    return "end: integer|Long expected";
            return null;
        };

        TimeRange.fromObject = function fromObject(object) {
            if (object instanceof $root.payload.TimeRange)
                return object;
            var message = new $root.payload.TimeRange();
            if (object.start != null)
                if ($util.Long)
                    (message.start = $util.Long.fromValue(object.start)).unsigned = false;
                else if (typeof object.start === "string")
                    message.start = parseInt(object.start, 10);
                else if (typeof object.start === "number")
                    message.start = object.start;
                else if (typeof object.start === "object")
                    message.start = new $util.LongBits(object.start.low >>> 0, object.start.high >>> 0).toNumber();
            if (object.end != null)
                if ($util.Long)
                    (message.end = $util.Long.fromValue(object.end)).unsigned = false;
                else if (typeof object.end === "string")
                    message.end = parseInt(object.end, 10);
                else if (typeof object.end === "number")
                    message.end = object.end;
                else if (typeof object.end === "object")
                    message.end = new $util.LongBits(object.end.low >>> 0, object.end.high >>> 0).toNumber();
            return message;
        };

        TimeRange.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                if ($util.Long) {
                    var long = new $util.Long(0, 0, false);
                    object.start = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.start = options.longs === String ? "0" : 0;
                if ($util.Long) {
                    var long = new $util.Long(0, 0, false);
                    object.end = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.end = options.longs === String ? "0" : 0;
            }
            if (message.start != null && message.hasOwnProperty("start"))
                if (typeof message.start === "number")
                    object.start = options.longs === String ? String(message.start) : message.start;
                else
                    object.start = options.longs === String ? $util.Long.prototype.toString.call(message.start) : options.longs === Number ? new $util.LongBits(message.start.low >>> 0, message.start.high >>> 0).toNumber() : message.start;
            if (message.end != null && message.hasOwnProperty("end"))
                if (typeof message.end === "number")
                    object.end = options.longs === String ? String(message.end) : message.end;
                else
                    object.end = options.longs === String ? $util.Long.prototype.toString.call(message.end) : options.longs === Number ? new $util.LongBits(message.end.low >>> 0, message.end.high >>> 0).toNumber() : message.end;
            return object;
        };

        TimeRange.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        TimeRange.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/payload.TimeRange";
        };

        return TimeRange;
    })();

    payload.CRUDResult = (function() {

        function CRUDResult(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        CRUDResult.prototype.value = false;

        CRUDResult.create = function create(properties) {
            return new CRUDResult(properties);
        };

        CRUDResult.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.value != null && Object.hasOwnProperty.call(message, "value"))
                writer.uint32(8).bool(message.value);
            return writer;
        };

        CRUDResult.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        CRUDResult.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.payload.CRUDResult();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.value = reader.bool();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        CRUDResult.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        CRUDResult.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.value != null && message.hasOwnProperty("value"))
                if (typeof message.value !== "boolean")
                    return "value: boolean expected";
            return null;
        };

        CRUDResult.fromObject = function fromObject(object) {
            if (object instanceof $root.payload.CRUDResult)
                return object;
            var message = new $root.payload.CRUDResult();
            if (object.value != null)
                message.value = Boolean(object.value);
            return message;
        };

        CRUDResult.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults)
                object.value = false;
            if (message.value != null && message.hasOwnProperty("value"))
                object.value = message.value;
            return object;
        };

        CRUDResult.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        CRUDResult.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/payload.CRUDResult";
        };

        return CRUDResult;
    })();

    return payload;
})();

module.exports = $root;
