import protobuf from 'protobufjs';
import axios from 'axios'
import { Notification } from 'element-ui'
import { getAccessToken, removeAccessToken, removeRefreshToken, getRefreshToken, setAccessToken, setRefreshToken, } from '@/util/auth'
import { enums, framework, passport } from '@/proto/payload';
import { refreshToken } from '@/api/login'
const ErrorNo = enums.ErrorNo;
// request拦截器
const service = axios.create({
  baseURL: '/api', // /api
  timeout: 20000,
  withCredentials: false,
  // responseType: 'arraybuffer'
})

// request拦截器
service.interceptors.request.use(
  config => {
    // console.log(config);
    // config.transformResponse = transformResponseFactory(config.resType);
    // config.data = transformRequest(config?.data);
    // console.log(config.data);

    if (getAccessToken()) {
      config.headers['Authorization'] = getAccessToken();
    }

    config.headers['Accept'] = 'application/json, text/plain, */*';

    config.headers['Content-Type'] = 'application/json';

    return config
  },
  error => {
    // console.log(error)
    Promise.reject(error)
  }
)

// response 拦截器
service.interceptors.response.use(
  response => {
    const data = response.data;
    // if (data instanceof framework.PBMessageResponse) {
    // TODO 处理错误码
    const { code, msg } = data;
    // console.log(`errorNo = ${errorNo}, msg = ${msg}`);
    if (code === ErrorNo.SUCCESS) {
      Notification.success({
        message: '成功',
        duration: 3000
      });
      return data
    }
    if (code === ErrorNo.SERVICE_NOT_USABLE) {
      Notification.error({
        message: '服務器正忙',
        duration: 3000
      });
    }

    if (code === ErrorNo.ERROR) {
      Notification.error({
        message: '服務器正忙',
        duration: 3000
      });
    }
    // 
    if (code === ErrorNo.PAY_CHANNEL_CLOSE) {
      Notification.error({
        message: '儲值管道已關閉',
        duration: 4000
      });
      return Promise.reject(code)
    }
    // 
    if (code === ErrorNo.REQUIRE_RE_LOGIN) {
      Notification.error({
        message: '帳號已修改，請重新登入',
        duration: 4000
      });
      setTimeout(() => {
        removeAccessToken()
        removeRefreshToken()
        location.reload()
      }, 2000)
      return
    }

    if (code === ErrorNo.REFRESH_TOKEN_EXPIRED || code === ErrorNo.REFRESH_TOKEN_INVALIDATE || code == ErrorNo.ACCESS_TOKEN_ERROR || code === ErrorNo.ACCESS_TOKEN_AUTH_FAIL) {
      Notification.error({
        message: '登入狀態過期，請重新登入',
        duration: 4000
      });
      setTimeout(() => {
        removeAccessToken()
        removeRefreshToken()
        location.reload()
      }, 2000)
      return
    }

    // if (errorNo === ErrorNo.ACCESS_TOKEN_EXPIRED) {
    //   const params = passport.RefreshTokenRequest.create({
    //     appId: '8',
    //     refreshToken: getRefreshToken(),
    //     accessToken: getAccessToken()
    //   })
    //   refreshToken(params).then((res) => {
    //     console.log(res, '刷新token');
    //     if (res) {
    //       const data = passport.LoginResponse.toObject(res);
    //       setAccessToken(data.accessToken);
    //       setRefreshToken(data.refreshToken);
    //       location.reload()
    //     }
    //   })
    //   return
    // }
    // return Promise.reject(errorNo)
    // }
    return data
  },
  error => {
    console.log(error)
    if (error.code === 'ERR_BAD_RESPONSE') {
      Notification.error({
        title: '服務器正忙',
        duration: 5000
      })
    }
    if (error.toString().indexOf('Error: timeout') !== -1) {
      console.log('超时？');
      Notification.error({
        title: '網絡請求超時',
        duration: 5000
      })

      return Promise.reject(error)
    }
    return Promise.reject(error.response.data)
  }
)

// probuffer 处理
function isArrayBuffer(obj) {
  return obj.toString() === '[object ArrayBuffer]';
}


const transformResponseFactory = (responseType) => function (rawResponse) {
  // 判断response是否为arrayBuffer
  if (rawResponse == null || !isArrayBuffer(rawResponse)) {
    return rawResponse;
  }

  try {
    const buf = protobuf.util.newBuffer(rawResponse);
    const encryptedResponse = framework.PBMessageResponse.decode(buf);
    if (encryptedResponse.messageData && encryptedResponse.errorNo === ErrorNo.SUCCESS && responseType) {
      const bufferT = encryptedResponse.messageData
      return responseType.decode(bufferT);
    } else {
      return encryptedResponse;
    }
  } catch (err) {

  }
};

function transformRequest(data) {
  if (data === null) {
    return null;
  }
  return new Blob([data], { type: 'buffer' });
}

export default service
