<template>
  <div>
    <div class="top"><img src="@/assets/images/logo.png" class="logo" /></div>
    <div class="zhan"></div>
    <div class="nr">
      <div class="login">
        <p class="h1">LMD帳號登入</p>
        <img class="imgLine" src="@/assets/images/line.png" alt="" />
        <div>
          <div class="uername" :class="isError ? 'username-error' : ''">
            <select
              v-model="phonePrefix"
              aria-placeholder="請選擇伺服器"
              @change="handleChangeServe()"
            >
              <option selected hidden disabled value="">請選擇</option>
              <option :value="item" v-for="item in phoneTop" :key="item">
                {{ item }}
              </option>
            </select>
            <input
              placeholder="請輸入帳號"
              v-model="username"
              type="number"
              id="username"
              @blur="handleUserBulr"
              @focus="handleUserFocus"
            />
          </div>
          <p class="error">{{ isError ? "請輸入正確的手機號" : "" }}</p>
          <div class="password-box">
            <img src="@/assets/images/password.png" alt="" />
            <input
              placeholder="請輸入密碼"
              type="password"
              id="password"
              v-model="password"
              class="password"
              @blur="handelPassBulr"
            />
          </div>
          <p class="error">{{ isErrorPass ? "請輸入6-10位密碼" : "" }}</p>
          <input
            type="submit"
            value="登入"
            class="dr"
            @click="handleSubmit()"
            style="margin-bottom: 0.3rem"
          />
        </div>
        <div style="font-size: 0.18rem; color: #101010" class="info-box">
          <span>使用第三方平臺帳號登入</span>
          <div class="link-box">
            <a href="http://nbasupstar.lmdgame.tw/privacy/" target="_blank">隱私政策</a>
            <a href="http://nbasupstar.lmdgame.tw/contract/" target="_blank" style="margin-left: 18px;">用戶協議</a>
          </div>
        </div>
        <button class="other apple" id="apple-login" @click="handleIOS">
          <span></span>
        </button>
        <fb-signin-button
          :params="fbSignInParams"
          @success="onFbSignInSuccess"
          @error="onFbSignInError"
        >
          <a class="other face"></a>
        </fb-signin-button>
        <a class="other g_id_signin" id="g_id_signin" data-type="icon">
          <div></div>
        </a>
      </div>
    </div>
    <div class="footer">
      <p>Copyright © 2022</p>
      <p>香港樂曼多有限公司保留一切權利</p>
    </div>
  </div>
</template>

<script>
import { login, rdLogin } from "@/api/login";
import { gameList } from "@/api/game";
import moment from "moment";
import { passport, enums, game } from "@/proto/payload.js";
import { Notification } from "element-ui";
import { setAccessToken, setRefreshToken, setUserInfo } from "@/util/auth.js";
import store from "@/store";
import { checkPhone, checkPassWord } from "@/util/check.js";
import { timeZone } from "@/util/common.js";

const LoginType = enums.LoginType;
const phoneTop = ["+886", "+852", "+853", "+60", "+65", "+86", "+63", "+62"];
export default {
  data() {
    return {
      username: "",
      password: "",
      googleSignInParams: {
        client_id:
          "498606738451-ufrut3a7nu3m2023kg06udf2r506627a.apps.googleusercontent.com",
      },
      fbSignInParams: {
        scope: "email",
        return_scopes: true,
      },
      phoneTop: phoneTop,
      phonePrefix: "+886",
      isError: false,
      isErrorPass: false,
    };
  },
  methods: {
    handleSubmit() {
      if (checkPhone(this.phonePrefix, this.username)) {
        return;
      }
      if (checkPassWord(this.password)) {
        return;
      }
      const params = {
        appId: "8",
        phonePrefix: this.phonePrefix,
        phone: this.username.trim(),
        password: this.password,
      };
      const loading = this.$loading({
        lock: true,
        text: "登陸中",
        spinner: "el-icon-loading",
      });
      login(params)
        .then((res) => {
          if (res.code === 0) {
            const data = res.data;
            const userInfo = {
              name: data.accounts.otherPlatName,
            };
            setAccessToken(data.info.access_token);
            setRefreshToken(data.info.refresh_token);
            setUserInfo(JSON.stringify(userInfo));
            loading.close();
            this.$router.push({
              path: "/home",
            });
          } else {
            loading.close();
            Notification.error({
              message: "賬號或密碼錯誤",
              duration: 3000,
            });
          }

        })
        .catch((res) => {
          loading.close();
          // if (res.code !== 200) {
          //   Notification.error({
          //     message: "賬號或密碼錯誤",
          //     duration: 3000,
          //   });
          // }
        });
    },
    onSignInError(error) {
      // console.log("OH NOES", error);
    },
    // facebook
    onFbSignInSuccess(response) {
      const profile = response.authResponse.accessToken;
      this.getRdLogin(profile, LoginType.LOGIN_BY_FB);
    },
    onFbSignInError(error) {
      console.log("OH NOES", error);
    },

    // ios
    async handleIOS() {
      const data = await AppleID.auth.signIn();
      this.getRdLogin(data.authorization.id_token, LoginType.LOGIN_BY_APPLE);
    },

    // 谷歌第三方登录
    getRdLogin(key, item) {
      const params = {
        appId: "8",
        account: key,
        loginType: item,
      };
      // console.log(params);
      rdLogin(params)
        .then((res) => {
          if (res.code === 0) {
            const data = res.data;
            const userInfo = {
              name: data.accounts.otherPlatName,
            };
            setAccessToken(data.info.access_token);
            setRefreshToken(data.info.refresh_token);
            setUserInfo(JSON.stringify(userInfo));
            this.$router.push({
              path: "/home",
            });
          } else {
            Notification.error({
              message: "帳號不存在",
              duration: 3000,
            });
          }

        })
        .catch((res) => {
          if (res == 1) {
            Notification.error({
              message: "帳號不存在",
              duration: 3000,
            });
          }
        });
    },

    // 获取手机号前缀
    getGamneList() {
      gameList().then((res) => {
        const data = game.GameListResponse.toObject(res);
        this.phoneTop = data.phonePrefix || [];
      });
    },

    // 账号失焦
    handleUserBulr() {
      this.isError = checkPhone(this.phonePrefix, this.username);
      console.log(this.isError);
    },
    // 账号聚焦
    handleUserFocus() {
      this.isError = false;
    },
    // 密码失焦
    handelPassBulr() {
      this.isErrorPass = checkPassWord(this.password);
    },

    // 选择手机号前缀
    handleChangeServe() {
      if (this.username.length == 0) {
        this.isError = false;
        return;
      }
      this.isError = checkPhone(this.phonePrefix, this.username);
    },

    handleCredentialResponse(e) {
      this.getRdLogin(e.credential, LoginType.LOGIN_BY_GOOGLE);
    },
  },
  mounted() {
    // this.getGamneList();

    timeZone();
    // TODO: 换appid 苹果
    AppleID.auth.init({
      response_type: "code",
      clientId: "com.lmdgame.nbamvp.tw.web",
      scope: "email",
      redirectURI: "https://nbapay.lmdgame.tw/api/v1/sign/apple",
      usePopup: true,
      state: "state",
    });
    document.addEventListener("AppleIDSignInOnSuccess", (data) => {
      // console.log(data, "ios success");
    });
    document.addEventListener("AppleIDSignInOnFailure", (error) => {
      // console.log(error, "ios error");
    });
  },
  created() {
    const script = document.createElement("script");
    script.src = "https://accounts.google.com/gsi/client";
    document.body.appendChild(script);
    // TODO: 换appid 谷歌
    window.addEventListener("load", () => {
      window.google.accounts.id.initialize({
        // 主要就是填写client_id
        client_id:
          "498606738451-ufrut3a7nu3m2023kg06udf2r506627a.apps.googleusercontent.com",
        callback: this.handleCredentialResponse,
      });
      window.google.accounts.id.prompt(false);
      // 设置按钮的样式等
      window.google.accounts.id.renderButton(
        document.getElementById("g_id_signin"),
        {
          theme: "",
          size: "large",
          type: "icon",
          text: "signin_with",
          shape: "circle",
        }
      );
    });
  },
};
</script>

<style lang="less" scoped>
.top {
  position: fixed;
}
.zhan {
  width: 100%;
}
.nr {
  width: 100%;
  height: 10.4rem;
  background: url(../assets/images/background_1.png) center no-repeat;
  background-size: 100% 10.4rem;
  position: relative;
}

.login {
  width: 4.52rem;
  height: 4.4rem;
  background: url(../assets/images/dikuang.png) top no-repeat;
  background-size: 5.92rem 5.8rem;
  position: absolute;
  top: 2rem;
  right: 1rem;
  padding: 0.7rem;
}

.nr input {
  width: 4.52rem;
  height: 0.6rem;
  background-color: transparent;
  color: #b6b9bd;
  font-size: 0.22rem;
}

.info-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .link-box {
    display: flex;
    a {
      cursor: pointer;
    }
  }
}

.h1 {
  text-align: left;
  font-size: 0.26rem;
  color: #101010;
  font-weight: 600;
  margin-bottom: 0.47rem;
}

.imgLine {
  top: 1.25rem;
  left: 0;
  position: absolute;
  width: 5.92rem;
}

.dr {
  margin-left: 0 !important;
  font-size: 0.28rem !important;
  color: #fff !important;
  width: 4.6rem !important;
  font-weight: 600;
  background: url(../assets/images/loginButton.png) no-repeat;
  cursor: pointer;
  height: 0.66rem !important;
}

.other {
  width: 0.4rem;
  height: 0.4rem;
  float: left;
  margin-right: 0.2rem;
  margin-top: 0.1rem;
}

.apple {
  background: url(../assets/images/apple.png) center no-repeat;
  background-size: 0.4rem 0.4rem;
}

.face {
  background: url(../assets/images/face.png) center no-repeat;
  background-size: 0.4rem 0.4rem;
}

.google {
  background: url(../assets/images/google.png) center no-repeat;
  background-size: 0.4rem 0.4rem;
}
.uername {
  display: flex;
  align-items: center;
  box-sizing: border-box;
  select {
    width: 1.5rem;
    height: 0.6rem;
    background: #e0e0e0 url(../assets/images/caret-bottom.png) no-repeat center;
    background-size: 0.2rem 0.2rem;
    background-position-x: 1.2rem;
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    font-size: 0.22rem;
    color: #b6b9bd;
    padding-left: 0.2rem;
    border-right: 1px solid #c7c9cb;
    box-sizing: border-box;
  }
  input {
    width: 3.02rem;
    height: 0.6rem;
    background-color: #fff;
    color: #b6b9bd;
    font-size: 0.22rem;
    margin: 0;
    box-sizing: border-box;
    // background: url(../assets/images/accoun_2.png) no-repeat !important;
    padding-left: 0.2rem;
    padding-right: 0.2rem;
  }
}

.password-box {
  background-color: #fff;
  display: flex;
  align-items: center;
  padding-left: 0.2rem;
  width: 4.52rem;
  box-sizing: border-box;
  img {
    width: 0.17rem;
    display: flex;
  }
  .password {
    padding-left: 20px;
    box-sizing: border-box;
    background-color: #fff !important;
    margin-bottom: 0;
    width: 3.9rem;
  }
}

.error {
  width: 100%;
  height: 0.3rem;
  line-height: 0.3rem;
  color: rgb(245, 108, 108);
  font-size: 0.12rem;
  font-family: "Helvetica Neue", Helvetica, "PingFang SC", "Hiragino Sans GB",
    "Microsoft YaHei", SimSun, sans-serif;
}

.username-error {
  // border: 1px solid rgb(245, 108, 108);
}
</style>