<template>
  <div class="mobile-record">
    <div class="home-gz">
      <span>儲值規則</span>
      <p class="one">
        1.若您為未成年者，儲值前請經由法定代理人同意進行消費儲值。
      </p>
      <p>
        2.依據【通訊交易解除權合理例外情事使用準則】，本次購買之遊戲商場貨幣或商品不適用消費者保護法七天猶豫期規定，購買前請務必確認商品資訊，以免自身權益受損。
      </p>
    </div>
    <div class="home-search">
      <select
        v-model="nowServe"
        aria-placeholder="請選擇伺服器"
        @change="handleChangeServe()"
      >
        <option selected hidden disabled value="">請選擇伺服器</option>
        <option :value="item.id" v-for="item in serveArr" :key="item.id">
          {{ item.name }}
        </option>
      </select>
      <select v-model="nowUser">
        <option selected hidden disabled value="">請選擇遊戲角色</option>
        <option :value="item.gid" v-for="item in roleArr" :key="item.gid">
          {{ item.name }}
        </option>
      </select>
      <div class="search-input">
        <input
          type="text"
          placeholder="請輸入商品名"
          class="sr"
          v-model="key"
        />
        <img src="@/assets/images/search.png" alt="" />
      </div>
      <div class="serrch-other">
        <input type="button" value="查詢" class="cx" @click="handleSearch()" />
        <div class="return_btn" @click="handleBuy()">
          <img src="@/assets/images/return.png" alt="" />
          <a href="">購買商品</a>
        </div>
      </div>
    </div>
    <table
      class="mobole-table"
      v-loading="loading"
      element-loading-text="拼命加载中"
    >
      <thead>
        <tr>
          <th>儲值時間</th>
          <th>游戲名稱</th>
          <th>伺服器</th>
          <th>角色ID</th>
          <th>儲值管道</th>
          <th>購買商品</th>
          <th>商品價格</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, index) in dataArr" :key="index">
          <td style="font-size: 0.18rem">{{ item.times }}</td>
          <td>絕對巨星</td>
          <td>{{ item.zoneName }}</td>
          <td>{{ item.rid }}</td>
          <td>
            {{
              item.payType == 1
                ? "Google"
                : item.payType == 2
                ? "Appstore"
                : item.payType == 3
                ? "Mycard"
                : item.payType == 4
                ? "HMS"
                : "--"
            }}
          </td>
          <td>{{ item.productName }}</td>
          <td>{{ item.price }} 臺幣</td>
        </tr>
        <div class="no-info" v-if="!(total > 0)">暫無數據</div>
      </tbody>
    </table>
    <el-pagination
      class="tz"
      layout="prev, pager, next"
      :total="total"
      :page-size="3"
      @current-change="handleCurrentChange"
    >
    </el-pagination>
  </div>
</template>

<script>
import { orderList, serverList, roleList } from "@/api/game";
import { getAccessToken } from "@/util/auth";
import { game } from "@/proto/payload";
export default {
  data() {
    return {
      dataArr: [],
      key: "",
      pagination: {
        page: 1,
        pageSize: 3,
      },
      loading: true,
      nowServe: "",
      nowUser: "",
      serveArr: [],
      roleArr: [],
      total: 0,
    };
  },
  methods: {
    handleBuy() {
      this.$router.push({
        path: "/home",
      });
    },

    getOrderList() {
      const params = {
        // appId: "8",
        ...this.pagination,
        keys: this.key,
        serverId: this.nowServe,
        currency: "TWD",
        accessToken: getAccessToken(),
        rid: this.nowUser
      };
      this.loading = true;
      orderList(params)
        .then((res) => {
          const data = res.data;
          this.dataArr = data.records;
          this.total = data.total;
          this.loading = false;
        })
        .catch((res) => {
          this.loading = false;
        });
    },

    // 获取角色列表
    getRoleList() {
      const params ={
        // appId: "8",
        serverId: this.nowServe || "",
        accessToken: getAccessToken(),
      };
      roleList(params)
        .then((res) => {
          // const data = game.RoleListResponse.toObject(res);
          this.roleArr =res.data;
        })
        .catch((res) => { });
    },

    getServerList() {
      const params = {
        appId: "8",
      };
      // console.log(params);
      serverList(params)
        .then((res) => {
          // const data = game.ServerListResponse.toObject(res);
          this.serveArr =res.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    handleChangeServe() {
      this.nowUser = "";
      this.getRoleList();
    },

    handleSearch() {
      this.pagination = {
        page: 1,
        pageSize: 6,
      };
      this.getOrderList();
    },

    // 下一页
    handleCurrentChange(val) {
      this.pagination.page = val;
      this.getOrderList();
    },
  },
  mounted() {
    this.getOrderList();
    this.getServerList();
  },
};
</script>

<style lang="less" scoped>
.mobile-record {
  padding: 0 0.4rem;

  .home-gz {
    width: 100%;

    span {
      font-size: 0.2rem;
      color: #b6b9bd;
    }

    p {
      font-size: 0.15rem;
      color: #b6b9bd;
    }

    .one {
      margin-top: 0.06rem;
    }
  }

  .home-search {
    width: 100%;
    margin-top: 0.3rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;

    select {
      margin-bottom: 0.3rem;
      width: 3.9rem;
      height: 0.7rem;
      font-size: 0.28rem;
      color: #b6b9bd;
      padding-left: 0.22rem;
      // margin-bottom: 0.3rem;
      padding-left: 0.2rem;
      position: relative;
      background: #f7f7f7 url(../../assets/images/caret-bottom.png) no-repeat center;
      background-size: 0.25rem 0.25rem;
      background-position-x: 3.4rem;
      appearance: none;
      -moz-appearance: none;
      -webkit-appearance: none;
    }

    .cx {
      width: 1.46rem;
      height: 0.5rem;
      background: url(../../assets/images/an3.png) center no-repeat;
      background-size: 1.46rem 0.5rem;
      color: #f7f7f7;
      font-size: 0.22rem;
    }

    .search-input {
      display: flex;
      align-items: center;
      background: #fff;
      padding-right: 10px;
      box-sizing: border-box;
      width: 3.9rem;
      height: 0.7rem;

      .sr {
        font-size: 0.28rem;
        color: #b6b9bd;
        width: 3rem;
        height: 0.7rem;
        margin-right: 0.2rem;
        padding-left: 0.2rem;
      }

      img {
        width: 0.25rem;
        height: 0.25rem;
      }
    }

    .serrch-other {
      width: 3.9rem;
      display: flex;
      justify-content: space-between;

      .cx {
        width: 1.8rem;
        height: 0.7rem;
        background: url(../../assets/images/an3.png) center no-repeat;
        background-size: 100% 100%;
        color: #f7f7f7;
        font-size: 0.22rem;
      }

      .return_btn {
        display: flex;
        align-items: center;

        img {
          width: 0.3rem;
          margin-right: 0.05rem;
        }

        a {
          color: #8d9196;
          font-size: 0.3rem;
          text-decoration: underline;
          float: right;
        }
      }
    }
  }

  table {
    margin-top: 0.3rem;
    position: relative;
    width: 100%;
    display: block;
    display: flex;

    thead {
      float: left;
      width: 2rem;
      background: url("../../assets/mobile/record-th.png");

      tr {
        width: 100%;
        display: inline-block;

        th {
          width: 100%;
          height: 1.3rem;
          line-height: 1.3rem;
          text-align: center;
        }
      }
    }

    tbody {
      flex: 1;
      background: url("../../assets/mobile/table-body.png");
      display: flex;
      overflow: hidden;

      tr {
        width: 2rem;
        position: relative;

        td {
          display: block;
          height: 1.3rem;
          width: 100%;
          line-height: 1.3rem;
          font-size: 0.22rem;
          text-align: center;
          font-weight: 600;
          overflow: hidden; //超出的文本隐藏
          text-overflow: ellipsis; //溢出用省略号显示
          white-space: nowrap; // 默认不换行；
        }

        &:not(:last-child) {
          &::after {
            content: "";
            position: absolute;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
            display: block;
            width: 1px;
            height: 95%;
            background: url("../../assets/mobile/table-line.png");
          }
        }
      }
    }

    th,
    td {
      display: block;
    }
  }

  .tz {
    margin-top: 0.1rem;
    height: 1rem;
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .no-info {
    width: 100%;
    height: 100%;
    background: #bec5cd;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #717882;
    font-weight: bold;
    font-size: 0.35rem;
  }

  ::v-deep {
    .el-pager li {
      background: none;
      color: #77797f;
      font-size: 0.2rem;
      min-width: 0.5rem;
    }

    .el-pager li.active {
      color: #fff;
      cursor: default;
    }

    .el-pager li :hover {
      color: #fff !important;
    }

    .el-pager li.btn-quicknext,
    .el-pager li.btn-quickprev {
      color: #77797f;
    }

    .el-icon-arrow-right:before {
      content: ""; //右边“下一页”三角箭头
    }

    .el-icon-arrow-left:before {
      content: ""; //左边“上一页”三角剪头
    }

    .el-pagination button:disabled {
      background-color: transparent;
    }

    .el-pagination .btn-next,
    .el-pagination .btn-prev {
      background: url(../../assets/images/xia.png) center no-repeat;
      background-size: 0.2rem 0.24rem;
    }

    .el-pagination .btn-prev {
      background: url(../../assets/images/shang.png) center no-repeat;
      background-size: 0.2rem 0.24rem;
    }

    .el-pagination button {
      min-width: 0.5rem;
    }
  }
}</style>