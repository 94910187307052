export const checkPhone = (phonePrefix, val) => {
    let errorFlag = false
    const numberReg = new RegExp(/^[0-9]*$/)
    const TW_RULE = /(^[9][0-9]{8}$)|(^[0][9][0-9]{8}$)/ // 台湾
    const HK_RULE = /^[5,6,7,9][0-9]{7}$/ // 香港
    const MA_RULE = /^[6][0-9]{7}$/ // 澳门
    const MAS_RULE = /(^1[1,5]\d{8}$)|(^[0,2,3,4,5,6,7,8,9]\d{8}$)|(^1[0,2,3,4,6,7,8,9]\d{7}$)/ //马来西亚
    const RS_RULE = /^[8,9][0-9]{7}$/ // 新加坡
    const CH_RULE = /^1([358][0-9]|4[579]|66|7[0135678]|9[89])[0-9]{8}$/ //中国
    const PHS_RULE = /^9\d\d[0-9]{7}$/ //菲律宾
    const IDN_RULE = /((^9((\d{10})|(\d{9})))$)|(^8[1,2,3,5,6,8]((\d{9})|(\d{8}))$)|(^870(\d{7}|\d{8})$)/ //印尼
    if (val.length == 0) {
        return true
    }
    if (!numberReg.test(val)) {
        return true
    }
    // console.log(val.length, phonePrefix);
    switch (phonePrefix) {
        case "+886":
            if (!TW_RULE.test(val)) errorFlag = true
            break
        case "+852":
            if (!HK_RULE.test(val)) errorFlag = true
            break
        case "+853":
            if (!MA_RULE.test(val)) errorFlag = true
            break
        case "+60":
            if (!MAS_RULE.test(val)) errorFlag = true
            break
        case "+65":
            if (!RS_RULE.test(val)) errorFlag = true
            break
        case "+86":
            if (!CH_RULE.test(val)) errorFlag = true
            break
        case "+63":
            if (!PHS_RULE.test(val)) errorFlag = true
            break
        case "+62":
            if (!IDN_RULE.test(val)) errorFlag = true
            break
    }
    // console.log(errorFlag);
    return errorFlag
}

export const checkPassWord = (val) => {
    if (val.length == 0) {
        return true
    }
    if (!(6 <= val.length && val.length <= 16)) {
        return true
    }
}