import router from './index.js'
import { getAccessToken, setRefreshToken } from "@/util/auth.js";
import { isPC } from "@/util/common";
import { getUrlParams } from "@/util/params";
// 路由守卫，判断token是否存在
router.beforeEach((to, from, next) => {
    console.log(to);
    if (getAccessToken()) {
        if (to.path === '/login') {
            next({ path: '/home' })
        }
        else {
            next()
        }
    } else {
        if (to.path !== '/login') {
            next('/login')
        }
        next()
    }
})
