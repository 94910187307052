export const getAccessToken = () => {
    return localStorage.getItem("accessToken")
}

export const removeAccessToken = () => {
    return localStorage.removeItem("accessToken")
}

export const setAccessToken = (token) => {
    localStorage.setItem("accessToken", token)
}

export const getRefreshToken = () => {
    return localStorage.getItem("refreshToken")
}

export const removeRefreshToken = () => {
    return localStorage.removeItem("refreshToken")
}

export const setRefreshToken = (token) => {
    localStorage.setItem("refreshToken", token)
}


export const setUserInfo = (val) => {
    localStorage.setItem("userInfo", val)
}

export const getUserInfo = () => {
    return localStorage.getItem("userInfo")
}

export const removeUserInfo = () => {
    return localStorage.removeItem("userInfo")
}
