import request from '@/util/request'
import { game, mycard } from '@/proto/payload.js'

// 获取游戏列表
export function gameList() {
    return request({
        url: '/v1/game/list',
        method: 'GET',
        // resType: game.GameListResponse
    })
}

// 获取区服列表
export function serverList(data) {
    return request({
        url: '/v1/game/web/serverList',
        method: 'GET',
        data
        // data: game.ServerListRequest.encode(data).finish(),
        // resType: game.ServerListResponse
    })
}

// 获取角色列表
export function roleList(data) {
    return request({
        url: '/v1/game/web/roleList',
        method: 'POST',
        data
        // data: game.RoleListRequest.encode(data).finish(),
        // resType: game.ServerListResponse
    })
}

// 获取商品列表
export function shopList(data) {
    return request({
        url: '/v1/game/web/productList',
        method: 'POST',
        data
        // data: game.ProductListRequest.encode(data).finish(),
        // resType: game.ProductPageResponse
    })
}


// 获取充值記錄
export function orderList(data) {
    return request({
        url: '/v1/game/web/orderList',
        method: 'POST',
        data,
        // data: game.OrderListRequest.encode(data).finish(),
        // resType: game.OrderPageResponse
    })
}

// 下单
export function order(data) {
    return request({
        url: '/v1/web/order/mycard/createOrder',
        method: 'POST',
        data
        // data: mycard.MyCardCreateOrderRequest.encode(data).finish(),
        // resType: mycard.MyCardCreateOrderResponse
    })
}


// 获取订单状态
export function cheackOrder(data) {
    return request({
        url: '/v1/web/order/mycard/checkOrder',
        method: 'POST',
        data
        // data: mycard.CheckOrderStateRequest.encode(data).finish(),
        // resType: mycard.CheckOrderStateResponse
    })
}