
export const isPC = () => {
    return !navigator.userAgent.match(/(iPhone|iPod|iPad|Android|Mobile|BlackBerry|Symbian|Windows Phone)/i)
}

export const timeZone = () => {
    let time = new Date();
    let timeZone = time.toLocaleTimeString('en-us', { timeZoneName: 'short' });
    let timeZoneId = Intl.DateTimeFormat().resolvedOptions().timeZone;
    // console.log(timeZoneId, timeZone);
}