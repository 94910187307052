<template>
  <router-view />
</template>
<script>
export default {
  mounted() {
    function windowsize() {
      var w = window.innerWidth;
      document.documentElement.style.fontSize = w / 10 + "px";
    }
    window.addEventListener("resize", windowsize);
    windowsize();
  },
};
</script>

<style>
</style>