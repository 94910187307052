const state = {
    appid: 8,
    name: ''
}

const mutations = {
    CHANGE_USERNAME: (state, name) => {
        state.name = name
    }
}

const actions = {
    setName({ commit }, name) {
        commit(CHANGE_USERNAME, name)
    }
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}