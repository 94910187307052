<template>
  <div class="content-record">
    <div class="gz">
      <span>儲值規則</span>
      <p class="one">
        1.若您為未成年者，儲值前請經由法定代理人同意進行消費儲值。
      </p>
      <p>
        2.依據【通訊交易解除權合理例外情事使用準則】，本次購買之遊戲商場貨幣或商品不適用消費者保護法七天猶豫期規定，購買前請務必確認商品資訊，以免自身權益受損。
      </p>
    </div>
    <form action="" method="get" class="cxk">
      <div style="display: flex">
        <select
          v-model="nowServe"
          aria-placeholder="請選擇伺服器"
          @change="handleChangeServe()"
        >
          <option selected hidden disabled value="">請選擇伺服器</option>
          <option :value="item.id" v-for="item in serveArr" :key="item.id">
            {{ item.name }}
          </option>
        </select>
        <select v-model="nowUser">
          <option selected hidden disabled value="">請選擇遊戲角色</option>
          <option :value="item.gid" v-for="item in roleArr" :key="item.gid">
            {{ item.name }}
          </option>
        </select>
        <div class="search-input">
          <input
            type="text"
            placeholder="請輸入商品名"
            class="sr"
            v-model="key"
          />
          <img src="@/assets/images/search.png" alt="" />
        </div>
        <input type="button" value="查詢" class="cx" @click="handleSearch()" />
      </div>
      <div class="return_btn" @click="handleBuy()">
        <img src="@/assets/images/return.png" alt="" />
        <a href="">購買商品</a>
      </div>
    </form>
    <div
      class="table-box"
      v-loading="loading"
      element-loading-text="拼命加载中"
    >
      <table>
        <tr>
          <th>儲值時間</th>
          <th>遊戲名稱</th>
          <th>伺服器</th>
          <th>角色ID</th>
          <th>儲值管道</th>
          <th>購買商品</th>
          <th>商品價格</th>
        </tr>
        <tr v-for="(item, index) in dataArr" :key="index">
          <td>{{ item.times }}</td>
          <td>絕對巨星</td>
          <td>{{ item.zoneName }}</td>
          <td>{{ item.rid }}</td>
          <td>
            {{
              item.payType == 1
                ? "Google"
                : item.payType == 2
                ? "Appstore"
                : item.payType == 3
                ? "Mycard"
                : item.payType == 4
                ? "HMS"
                : "--"
            }}
          </td>
          <td>{{ item.productName }}</td>
          <td>{{ item.price }} 臺幣</td>
        </tr>
      </table>
      <div class="no-info" v-if="!(total > 0)">暫無數據</div>
    </div>
    <div class="page">
      <el-pagination
        class="tz"
        layout="prev, pager, next"
        :total="total"
        :page-size="6"
        @current-change="handleCurrentChange"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import { orderList, serverList, roleList } from "@/api/game";
import { getAccessToken } from "@/util/auth";
import { game } from "@/proto/payload";
export default {
  data() {
    return {
      dataArr: [],
      key: "",
      loading: true,
      pagination: {
        page: 1,
        pageSize: 6,
      },
      nowServe: "",
      nowUser: "",
      serveArr: [],
      roleArr: [],
      total: 0,
    };
  },
  methods: {
    handleBuy() {
      this.$router.push({
        path: "/home",
      });
    },

    getOrderList() {
      const params = {
        // appId: "8",
        ...this.pagination,
        keys: this.key,
        serverId: this.nowServe,
        currency: "TWD",
        rid: this.nowUser
        // accessToken: getAccessToken(),
      };
      this.loading = true;
      orderList(params)
        .then((res) => {
          const data = res.data;
          this.dataArr = data.records;
          this.total = data.total;
          this.loading = false;
        })
        .catch((res) => {
          this.loading = false;
        });
    },

    // 获取角色列表
    getRoleList() {
      const params = {
        // appId: "8",
        serverId: this.nowServe || "",
        accessToken: getAccessToken(),
      }
      roleList(params)
        .then((res) => {
          // const data = res;
          this.roleArr = res.data;
        })
        .catch((res) => { });
    },

    getServerList() {
      const params = {
        appId: "8",
      };
      serverList(params)
        .then((res) => {
          // const data = res;
          this.serveArr = res.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    handleChangeServe() {
      this.nowUser = "";
      this.getRoleList();
    },

    handleSearch() {
      this.pagination = {
        page: 1,
        pageSize: 6,
      };
      this.getOrderList();
    },
    // 下一页
    handleCurrentChange(val) {
      console.log(val);
      this.pagination.page = val;
      this.getOrderList();
    },
  },
  mounted() {
    this.getOrderList();
    this.getServerList();
  },
};
</script>

<style lang="less" scoped>
.content-record {
  width: 100%;
  height: 100%;
}
.gz {
  width: 7.6rem;
  position: absolute;
  top: 1rem;
  right: 2.4rem;
  text-align: left;
  span {
    font-size: 0.18rem;
    color: #b6b9bd;
  }
  p {
    font-size: 0.14rem;
    color: #b6b9bd;
  }
  .one {
    margin-top: 14px;
  }
}
.cxk {
  position: absolute;
  top: 2.9rem;
  height: 0.52rem;
  width: 14.3rem;
  z-index: 5;
  left: 2.45rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  select {
    width: 2.74rem;
    height: 0.5rem;
    font-size: 0.22rem;
    color: #b6b9bd;
    padding-left: 0.22rem;
    // margin-bottom: 0.3rem;
    margin-right: 0.2rem;
    padding-left: 0.2rem;
    position: relative;
    background: #f7f7f7 url(../assets/images/caret-bottom.png) no-repeat center;
    background-size: 0.25rem 0.25rem;
    background-position-x: 2.3rem;
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
  }
  .cx {
    width: 1.46rem;
    height: 0.5rem;
    background: url(../assets/images/an3.png) center no-repeat;
    background-size: 1.46rem 0.5rem;
    color: #f7f7f7;
    font-size: 0.22rem;
  }
  .search-input {
    display: flex;
    align-items: center;
    background: #fff;
    padding-right: 10px;
    margin-right: 0.2rem;
    box-sizing: border-box;
    width: 2.74rem;
    .sr {
      font-size: 0.22rem;
      color: #b6b9bd;
      width: 1.9rem;
      height: 0.5rem;
      margin-right: 0.2rem;
      padding-left: 0.2rem;
    }
    img {
      width: 0.25rem;
      height: 0.25rem;
    }
  }

  .return_btn {
    display: flex;
    align-items: center;
    img {
      width: 0.23rem;
      margin-right: 0.05rem;
    }
    a {
      color: #8d9196;
      font-size: 0.24rem;
      text-decoration: underline;
      float: right;
    }
  }
}
.table-box {
  position: absolute;
  top: 3.7rem;
  left: 2.45rem;
  width: 14.3rem;
  min-height: 3.96rem;
}
table {
  width: 100%;
  z-index: 5;

  text-align: center;
  tr th {
    color: #e3ebf2;
    font-size: 0.24rem;
    background: #717882;
    height: 0.58rem;
    font-weight: 200;
  }
  tr td {
    color: #111111;
    font-size: 0.2rem;
    background: #bec5cd;
    height: 0.66rem;
    font-weight: 400;
    border-bottom: 1px solid #8e979f;
  }
}
.page {
  position: absolute;
  width: 100%;
  top: 8.6rem;
  display: flex;
  justify-content: center;
}
.tz {
  height: 0.26rem;
  z-index: 5;
}

::v-deep {
  .el-pager li {
    background: none;
    color: #77797f;
    font-size: 0.2rem;
    height: 0.26rem;
    line-height: 0.26rem;
  }

  .el-pager li.active {
    color: #fff;
    cursor: default;
  }

  .el-pager li :hover {
    color: #fff !important;
  }
  .el-pager li.btn-quicknext,
  .el-pager li.btn-quickprev {
    color: #77797f;
  }
  .el-icon-arrow-right:before {
    content: ""; //右边“下一页”三角箭头
  }
  .el-icon-arrow-left:before {
    content: ""; //左边“上一页”三角剪头
  }
  .el-pagination button:disabled {
    background-color: transparent;
  }
  .el-pagination .btn-next,
  .el-pagination .btn-prev {
    background: url(../assets/images/xia.png) center no-repeat;
    background-size: 0.16rem 0.2rem;
  }
  .el-pagination .btn-prev {
    background: url(../assets/images/shang.png) center no-repeat;
    background-size: 0.16rem 0.2rem;
  }
  .el-pagination button {
    height: 0.26rem;
    line-height: 0.26rem;
  }
}

.no-info {
  margin-top: 0.05rem;
  width: 100%;
  height: 3.96rem;
  background: #bec5cd;
  width: 14.3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #717882;
  font-weight: bold;
  font-size: 0.35rem;
}
</style>