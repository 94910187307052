import Vue from 'vue'
import App from './App.vue'
import router from './router/index'
import store from './store'
import '@/assets/styles/common.css'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css';
import GSignInButton from 'vue-google-signin-button'
import FBSignInButton from 'vue-facebook-signin-button'
import VueAppleSignin from 'vue-apple-signin';
import './router/router'


Vue.use(GSignInButton);
Vue.use(FBSignInButton)
Vue.config.productionTip = false
Vue.use(ElementUI);
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
