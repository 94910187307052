<template>
  <div class="mobile-home">
    <div class="home-gz">
      <span>儲值規則</span>
      <p class="one">
        1.若您為未成年者，儲值前請經由法定代理人同意進行消費儲值。
      </p>
      <p>
        2.依據【通訊交易解除權合理例外情事使用準則】，本次購買之遊戲商場貨幣或商品不適用消費者保護法七天猶豫期規定，購買前請務必確認商品資訊，以免自身權益受損。
      </p>
    </div>
    <div class="home-step-coentent">
      <div class="home-step">
        <div class="step-1 step" :class="jdnum == 1 ? 'active' : ''">
          <span class="step-num">STEP1</span>&nbsp;
          <span class="setp-name">選擇角色咨詢</span>
        </div>
        <div class="step-2 step" :class="jdnum == 2 ? 'active' : ''">
          <span class="step-num">STEP2</span>&nbsp;
          <span class="setp-name">選擇購買商品</span>
        </div>
        <img src="@/assets/mobile/jiantou.png" class="jian-right" alt="" />
      </div>
      <img
        src="@/assets/mobile/jiantou-bottom.png"
        class="jian-bottom"
        alt=""
      />
      <div class="home-step" style="margin-top: 0.5rem">
        <div class="step-1 step" :class="jdnum == 4 ? 'active' : ''">
          <span class="step-num">STEP4</span>&nbsp;
          <span class="setp-name">交易結果</span>
        </div>
        <div class="step-2 step" :class="jdnum == 3 ? 'active' : ''">
          <span class="step-num">STEP3</span>&nbsp;
          <span class="setp-name">進入付款流程</span>
        </div>
        <img src="@/assets/mobile/jiantou-right.png" class="jian-left" alt="" />
      </div>
    </div>
    <div class="home-content">
      <div class="conetent-1" v-if="jdnum == 1">
        <form action="" method="get" class="content-select">
          <select
            v-model="nowServe"
            aria-placeholder="請選擇伺服器"
            @change="handleChangeServe($event)"
          >
            <option selected hidden disabled value="">請選擇伺服器</option>
            <option :value="item.id" v-for="item in serveArr" :key="item.id">
              {{ item.name }}
            </option>
          </select>
          <select v-model="nowUser" :disabled="roleDisable">
            <option selected hidden disabled value="">請選擇遊戲角色</option>
            <option :value="item.gid" v-for="item in roleArr" :key="item.gid">
              {{ item.name }}
            </option>
          </select>
          <input
            type="button"
            value="下一步"
            class="next"
            @click="handleNext()"
          />
        </form>
      </div>
      <div class="conetent-2" v-if="jdnum == 2 || jdnum == 3 || jdnum == 4">
        <div class="search">
          <div class="search-box">
            <div class="search-input">
              <input
                type="text"
                v-model="shopKey"
                placeholder="請輸入商品名"
                class="sr"
              />
              <img src="@/assets/images/search.png" alt="" />
            </div>
            <input
              type="button"
              value="查詢"
              class="cx"
              @click="handleSeachShop()"
            />
          </div>
          <a @click="handleToggle()">更換角色</a>
        </div>
        <div class="shop-content">
          <div class="shop-qd">
            <div class="qd-list">
              <a class="oo"><img src="@/assets/mobile/mycard.png" /></a>
            </div>
          </div>
          <div
            class="shop-list"
            v-loading="loading"
            element-loading-text="获取商品中"
            @scroll="handleScroll($event)"
          >
            <div
              class="sp"
              v-for="(item, index) in shopArr"
              :key="index"
              @click="handleOk(item, index)"
              :class="index == okindex ? 'o2' : ''"
            >
              <span v-if="item.extraInfo"> {{ item.extraInfo }}</span>
              <img src="../../assets/mobile/baoxiang.png" />
              <p style="margin-top: 0.12rem;text-align: center;">{{ item.name }}</p>
              <p class="h2">
                {{ item.price / 100 }}（{{ item.currency }}）兌換
              </p>
            </div>
            <div class="no-info" v-if="noinfo">未找到相關商品，請重新輸入</div>
          </div>
        </div>
        <div class="shop-buy">
          <a class="pay" @click="handleBuy()">{{ buyTitle }}</a>
        </div>
      </div>
    </div>
    <!-- 弹窗 -->
    <div class="tan_scuess" v-if="jdnum == 3">
      <div class="tck">
        <p>請盡快完成儲值</p>
        <a
          style="color: #1e85eb; margin-left: 0.66rem"
          class="out"
          @click="handleNo()"
          >返回</a
        >
        <a style="color: #fff" class="ok" @click="getOrderCheck()"
          >已完成儲值</a
        >
      </div>
    </div>
    <div class="tan_scuess" v-if="jdnum == 4 && orderFlag">
      <div class="tck2">
        <p>已完成儲值</p>
        <a href="javascript:;" class="out2" @click="handleReturn()">返回</a>
      </div>
    </div>
    <div class="tan_scuess error" v-if="jdnum == 4 && !orderFlag">
      <div class="tck2">
        <img src="@/assets/images/wenhao.png" alt="" />
        <p>未查詢到結果</p>
        <p class="notice">若已完成支付，請稍後於游戲内確認</p>
        <a href="javascript:;" class="out2" @click="handleReturn()">確認</a>
      </div>
    </div>
  </div>
</template>

<script>
import { game, mycard } from "@/proto/payload.js";
import {
  gameList,
  serverList,
  roleList,
  shopList,
  order,
  cheackOrder,
} from "@/api/game";
import { getAccessToken } from "@/util/auth";
import { Notification } from "element-ui";
import moment from "moment";
import { getUrlParams } from "@/util/params";
export default {
  data() {
    return {
      jdnum: 1,
      okindex: null,
      shopArr: [],
      nowServe: "",
      nowUser: "",
      serveArr: [],
      roleArr: [],
      roleDisable: true,
      loading: true,
      shopKey: "",
      urlParams: "",
      pagination: {
        page: 1,
        pageSize: 10,
      },
      total: 0,
      serverItem: {},
      shopItem: {},
      userItem: {},
      changeFlag: false,
      orderFlag: false,
      buyTitle: "購買",
      noinfo: false,
    };
  },
  methods: {
    handleNext() {
      if (!this.nowUser) {
        Notification.warning({
          title: "提示",
          message: "請選擇游戲角色",
          duration: 3000,
        });
        return;
      }
      this.jdnum = 2;
      this.getShopList();
    },
    handleToggle() {
      this.jdnum = 1;
    },
    handleOk(item, index) {
      console.log(item, index);
      this.okindex = index;
      this.shopItem = item;
    },
    handleBuy() {
      if (this.buyTitle == "購買中") {
        return;
      }
      this.getOrder();
    },
    handleReturn() {
      this.jdnum = 2;
    },
    // 获取游戏列表
    getGameList() {
      gameList().then((res) => {
        console.log(res);
        const data = game.GameListResponse.toObject(res);
        console.log(data);
      });
    },
    // 获取区服列表
    getServerList() {
      const params = {
        appId: "8",
      };
      // console.log(params);
      serverList(params)
        .then((res) => {

          const data = res.data;
          this.serveArr = data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 切换区服
    handleChangeServe(e) {
      // console.log(e.target.value, "zzzzzzzzzzz");
      this.nowUser = "";
      this.serveArr.forEach((ele) => {
        if (ele.id == e.target.value) {
          this.serverItem = ele;
        }
      });
      this.getRoleList();
    },

    // 切换角色
    handleChangeUser(e) {
      console.log(e.target.value, "选择角色");
      this.roleArr.forEach((ele) => {
        if (ele.gid == e.target.value) {
          this.userItem = ele;
        }
      });
    },

    // 获取角色列表
    getRoleList() {
      const params = {
        appId: "8",
        serverId: this.nowServe || "",
        // accessToken: getAccessToken(),
      };
      roleList(params)
        .then((res) => {
          console.log(res);
          this.roleDisable = false;
          const data = res.data;
          this.roleArr = data;
          // console.log(data.list, data.list.length);
          if (res.data.length == 0) {
            Notification.info({
              title: "提示",
              message: "該服下暫無角色",
              duration: 3000,
            });
            return;
          }
        })
        .catch((res) => { });
    },

    // 获取商品列表
    getShopList() {
      const params = {
        // appId: "8",
        ...this.pagination,
        keys: this.shopKey,
        gid: this.nowUser,
        currency: "TWD",
        // accessToken: getAccessToken(),
        payType: 3,
        serverId: this.nowServe
      };
      this.loading = true;
      shopList(params)
        .then((res) => {
          this.loading = false;
          if (res.code === 0) {
            const data = res.data;
            this.noinfo = false;

            if (data.info.length === 0 && this.shopArr.length == 0) {
              this.noinfo = true;
              return;
            }
            this.shopArr = this.shopArr.concat(data.info);
            this.total = data.totalNum;
          }

        })
        .catch((res) => {
          this.loading = false;
          if (this.shopArr.length == 0) {
            this.noinfo = true;
          }
        });
    },

    // 搜索特定商品
    handleSeachShop() {
      this.shopArr = [];
      this.total = 0;
      this.pagination = {
        page: 1,
        pageSize: 10,
      };
      this.getShopList();
    },

    // 触底加载
    handleScroll(e) {
      const el = e.target;
      if (el.scrollTop + el.clientHeight >= el.scrollHeight) {
        if (this.total > this.shopArr.length) {
          this.pagination.page += 1;
          this.getShopList();
        }
      }
    },

    // 下单
    getOrder() {
      if (!this.shopItem.id) {
        Notification.warning({
          title: "提示",
          message: "請選擇商品",
          duration: 3000,
        });
        return;
      }
      this.buyTitle = "購買中";
      const params = {
        // appId: "8",
        channelID: "1",
        deviceID: "",
        cpOrderID: moment().unix(),
        extra: "",
        price: this.shopItem.price, //this.shopItem.price
        currency: "MYCARD",
        productID: this.shopItem.id.toString(), //
        productName: this.shopItem.name,
        productDesc: "",
        roleID: this.nowUser,
        roleName: this.userItem.name,
        roleLevel: "",
        serverID: this.nowServe,
        serverName: this.serverItem.name,
        // accessToken: getAccessToken(),
        area: "TWD",
      };
      // console.log(params);
      order(params)
        .then((res) => {

          this.jdnum = 3;
          const data = res.data;
          this.orderId = data.orderID;
          this.buyTitle = "購買";
          setTimeout(() => {
            window.open(data.transactionUrl, "_blank");
          });

        })
        .catch((res) => {
          if (res == 1) {
            Notification.error({
              message: "購買失敗，請重新購買",
              duration: 3000,
            });
          }
          this.buyTitle = "購買";
        });
    },

    // 验证下单
    getOrderCheck() {
      const params = {
        orderId: this.orderId,
        // accessToken: getAccessToken(),
      };
      cheackOrder(params).then((res) => {
        if (res.code === 0) {
          const data = res.data;
          if (data.state == 2 || data.state == 1) {
            this.jdnum = 4;
            this.orderFlag = false;
          }
          if (data.state == 3) {
            this.jdnum = 4;
            this.orderFlag = true;
          }
        }
        this.shopArr = [];
        this.pagination = {
          page: 1,
          pageSize: 10,
        };
        this.getShopList();
      });
    },

    // 返回
    handleNo() {
      this.jdnum = 2;
      this.shopArr = [];
      this.pagination = {
        page: 1,
        pageSize: 10,
      };
      this.getShopList();
    },
    // 支付成功
    handleSuccess() {
      this.jdnum = 4;
      this.changeFlag = false;
    },
  },
  mounted() {
    // this.getGameList();
    this.getServerList();
    const that = this;
    // document.addEventListener("visibilitychange", function () {
    //   //浏览器切换事件
    //   if (document.visibilityState == "hidden") {
    //   } else {
    //     if (that.jdnum == 3 && !that.changeFlag) {
    //       that.changeFlag = true;
    //     }
    //   }
    // });
  },
};
</script>

<style lang="less" scoped>
.mobile-home {
  padding: 0 0.4rem;

  .home-step-coentent {
    position: relative;

    .jian-bottom {
      position: absolute;
      top: 50%;
      width: 0.25rem;
      right: 22.5%;
      transform: translateY(-50%);
    }
  }

  .home-gz {
    width: 100%;

    span {
      font-size: 0.2rem;
      color: #b6b9bd;
    }

    p {
      font-size: 0.15rem;
      color: #b6b9bd;
    }

    .one {
      margin-top: 0.06rem;
    }
  }

  .home-step {
    margin-top: 0.4rem;
    position: relative;
    display: flex;
    justify-content: space-between;

    .step {
      width: 3.7rem;
      height: 0.7rem;
      background: url("../../assets/mobile/stepdikuang-02.png");
      background-size: 100% 100%;
      display: flex;
      align-items: center;
      padding-left: 0.4rem;
      box-sizing: border-box;

      .step-num {
        font-size: 0.3rem;
        font-weight: bold;
        line-height: 0.6rem;
      }

      .setp-name {
        font-size: 0.25rem;
        line-height: 0.6rem;
      }
    }

    .jian-right {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 0.2rem;
    }

    .jian-left {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 0.2rem;
      transform: rotate(180deg);
      transform: translate(-50%, -50%);
    }

    .active {
      background: url("../../assets/mobile/stepdikuang-01.png");
      color: #fff;
    }
  }

  .home-content {
    .conetent-1 {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;

      .content-select {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 1.2rem;

        select {
          width: 5.3rem;
          height: 0.8rem;
          font-size: 0.22rem;
          color: #b6b9bd;
          margin-bottom: 0.3rem;
          color: #333333;
          padding-left: 0.4rem;
          position: relative;
          background: #f7f7f7 url(../../assets/images/caret-bottom.png) no-repeat center;
          background-size: 0.3rem 0.3rem;
          background-position-x: 4.7rem;
          appearance: none;
          -moz-appearance: none;
          -webkit-appearance: none;
          box-sizing: border-box;
        }

        .next {
          margin-top: 0.2rem;
          width: 5.25rem;
          height: 0.7rem;
          background: url(../../assets/images/an.png) center no-repeat;
          background-size: 100% 100%;
          color: #fff;
          font-size: 0.28rem;
          font-weight: 400;
        }
      }
    }

    .conetent-2 {
      margin-top: 0.5rem;

      .search {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .search-box {
          display: flex;
          align-items: center;

          .search-input {
            display: flex;
            align-items: center;
            width: 4rem;
            background: #fff;
            padding-right: 10px;
            margin-right: 0.2rem;

            .sr {
              font-size: 0.22rem;
              color: #b6b9bd;
              width: 3.1rem;
              height: 0.6rem;
              margin-right: 0.2rem;
              padding-left: 20px;
            }

            img {
              width: 0.25rem;
              height: 0.25rem;
            }
          }
        }

        .cx {
          width: 2rem;
          height: 0.65rem;
          background: url(../../assets/images/an3.png) center no-repeat;
          background-size: 100% 100%;
          color: #fff;
          font-size: 0.22rem;
        }

        a {
          color: #8d9196;
          font-size: 0.24rem;
          text-decoration: underline;
          float: right;
        }
      }

      .shop-content {
        margin-top: 0.5rem;
        display: flex;
        justify-content: space-between;

        .shop-qd {
          width: 2.5rem;
          height: 6.5rem;
          background: #1c1d1e;
          padding-top: 0.12rem;
          box-sizing: border-box;

          .qd-list {
            width: 2.5rem;
            height: 4rem;
            background: url(../../assets/images/tong.jpg) center no-repeat;
            background-size: 2.3rem 4rem;
            box-sizing: border-box;
            padding: 0 0.1rem;
            padding-top: 0.8rem;

            a {
              width: 2.3rem;
              height: 0.75rem;
              display: block;
              margin-bottom: 0.036rem;
              display: flex;
              justify-content: center;
              align-items: center;

              img {
                width: 1.2rem;
                height: 0.5rem;
              }

              &:hover {
                width: 2.3rem;
                height: 0.75rem;
                background: url(../../assets/images/bb.png) center no-repeat;
                background-size: 100% 100%;
              }
            }

            .oo {
              background: url(../../assets/images/bb.png) center no-repeat;
              background-size: 100% 100%;
            }
          }
        }

        .shop-list {
          width: 5.3rem;
          height: 6.5rem;
          background: #1c1d1e;
          padding: 0.12rem;
          overflow: scroll;
          overflow-x: hidden;
          box-sizing: border-box;

          .sp {
            margin-bottom: 0.1rem;
            width: 100%;
            min-height: 2.2rem;
            background: url(../../assets/mobile/shop-box.png) center no-repeat;
            background-size: 100% 100%;
            position: relative;
            float: left;
            color: #fff;
            display: flex;
            flex-direction: column;
            align-items: center;

            span {
              display: block;
              width: 2rem;
              height: 0.6rem;
              position: absolute;
              background: url(../../assets/mobile/zengsong.png) center no-repeat;
              background-size: 100% 100%;
              top: 0.2rem;
              left: 0.01rem;
              color: #fff;
              font-size: 0.18rem;
              padding-left: 0.1rem;
              box-sizing: border-box;
            }

            img {
              margin-top: 0.16rem;
              width: 1.2rem;
            }

            .h2 {
              font-size: 0.26rem;
            }
          }

          .o2 {
            background: url(../../assets/images/wp_bg2.jpg) center no-repeat;
            background-size: 100% 100%;
          }

          .o2 p {
            color: #1d1d1e !important;
          }
        }
      }

      .shop-buy {
        margin-top: 0.6rem;
        width: 100%;
        display: flex;
        justify-content: center;

        .pay {
          text-align: center;
          display: block;
          width: 4.58rem;
          height: 0.8rem;
          line-height: 0.8rem;
          background: url(../../assets/images/an.png) center no-repeat;
          background-size: 100% 100%;
          color: #fff;
          font-size: 0.4rem;
          justify-content: center;
        }
      }
    }
  }

  .tan_scuess {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.5);
    z-index: 20;

    .tck2 {
      text-align: center;
      width: 4.66rem;
      height: 3.48rem;
      position: absolute;
      background: url(../../assets/images/tk.png) center no-repeat;
      background-size: 4.66rem 3.48rem;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 7;

      p {
        text-align: center;
        font-size: 0.32rem;
        margin-top: 1.96rem;
        margin-bottom: 0.3rem;
      }

      a {
        width: 2.3rem;
        height: 0.52rem;
        background: url(../../assets/images/an2.png) center no-repeat;
        background-size: 2.3rem 0.52rem;
        display: block;
        color: #fff;
        text-align: center;
        font-size: 0.26rem;
        line-height: 0.52rem;
        margin-left: 1.18rem;
      }
    }

    .tck {
      width: 5.14rem;
      height: 2.16rem;
      position: absolute;
      background: url(../../assets/images/tk2.png) center no-repeat;
      background-size: 5.14rem 2.16rem;
      color: #000;
      font-size: 0.32rem;
      text-align: center;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 7;

      p {
        margin-top: 0.48rem;
      }

      a {
        width: 1.76rem;
        height: 0.52rem;
        display: block;
        text-align: center;
        line-height: 0.52rem;
        float: left;
        font-size: 0.26rem;
        margin-right: 0.3rem;
        margin-top: 0.3rem;
      }
    }
  }

  .error {
    .tck2 {
      background: url(../../assets/images/dikuang-01.png) center no-repeat;

      img {
        margin-top: 0.3rem;
        width: 1.2rem;
      }

      p {
        margin-top: 0.1rem;
        margin-bottom: 0rem;
        color: #000;
      }

      .notice {
        margin-top: 0;
        font-size: 0.2rem;
        margin-bottom: 0.2rem;
        color: #656a73;
      }
    }
  }
}

.no-info {
  width: 100%;
  height: 100%;
  background: #bec5cd;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #717882;
  font-weight: bold;
  font-size: 0.3rem;
}
</style>