<template>
  <div class="mobile-layout">
    <div class="mobile-top">
      <div style="display: flex; align-items: center">
        <img src="@/assets/mobile/logo-home.png" class="mobile-logo" />
        <span style="color: #c1c4ce; margin-left: 0.2rem; font-size: 0.22rem"
          >樂曼多會員綫上儲值</span
        >
      </div>
      <div class="mobile-name" @click="handleShow()">
        <img src="@/assets/images/tx.png" />
        <span>{{ userInfo?.name || "用戶名" }}</span>
        <div class="mobile-ddh" v-if="showFlag == true">
          <span @click.stop="handleRecord()" key="1122">歷史交易</span>
          <span id="logout" @click="handleOut()">登出</span>
        </div>
      </div>
    </div>
    <div class="zhan"></div>
    <div class="mobile-center">
      <div class="center-img">
        <img src="@/assets/mobile/biaoti-01.png" alt="" />
      </div>
      <img src="@/assets/mobile/fengexian.png" class="feng" alt="">
      <div class="content">
        <router-view />
      </div>
    </div>
    <div class="zhan-bottom"></div>
    <div class="mobile-footer">
      <img src="../assets/mobile/logo-01.png" alt="" />
      <p>Copyright © 2022</p>
      <p>香港樂曼多有限公司保留一切權利</p>
    </div>
  </div>
</template>

<script>
import {
  removeAccessToken,
  removeRefreshToken,
  removeUserInfo,
  getUserInfo,
} from "@/util/auth";

export default {
  name: "layout",
  data() {
    return {
      showFlag: false,
      userInfo: JSON.parse(getUserInfo()),
    };
  },
  methods: {
    handleShow() {
      this.showFlag = !this.showFlag;
    },
    handleOut() {
      this.showFlag = false;
      removeAccessToken();
      removeRefreshToken();
      removeUserInfo();
      location.reload();
    },
    handleRecord() {
      this.showFlag = !this.showFlag;
      console.log(this.showFlag);
      this.$router.push({
        path: "/record",
      });
    },
  },
};
</script>

<style lang="less" scoped>
.mobile-layout {
  width: 100%;
  min-height: 100vh;
  background: url("../assets/mobile/home-bg.png") no-repeat;
  background-size: 100% 100%;
}
.mobile-top {
  width: 100%;
  height: 1.4rem;
  z-index: 10;
  background: url(../assets/mobile/top.png) center no-repeat;
  background-size: 100% 100%;
  position: fixed;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 0.8rem;
  box-sizing: border-box;
  .mobile-logo {
    width: 1.3rem;
  }
  .mobile-name {
    display: flex;
    align-items: center;
    img {
      width: 0.7rem;
      margin-right: 0.2rem;
    }
    span {
      font-size: 0.25rem;
      color: #c1c4ce;
    }
  }

  .mobile-ddh {
    position: absolute;
    width: 2.2rem;
    bottom: -1.24rem;
    z-index: 10;
    span {
      font-size: 0.18rem;
      color: #c1c4ce;
      display: block;
      background: #45484b;
      width: 1.22rem;
      height: 0.62rem;
      line-height: 0.62rem;
      text-align: center;
      &:hover {
        background: url(../assets/images/dl.png) center no-repeat;
        background-size: 1.22rem 0.62rem;
      }
    }
  }
}

.zhan {
  width: 100%;
  height: 1.4rem;
}

.mobile-center {
  margin: 0 0.6rem;
  height: 16rem;
  background: url("../assets/mobile/dikuang-03.png") no-repeat;
  background-size: 100% 100%;
  margin-top: 0.8rem;
  .center-img {
    text-align: center;
    img {
      margin-top: 0.5rem;
      margin-left: 0.5rem;
      width: 100%;
    }
  }
  .feng{
    width: 100%;
  }
}

.mobile-footer {
  width: 100%;
  height: 2rem;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background: url("../assets/mobile/bottom.png");
  background-size: 100% 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  padding-top: 0.5rem;
  img {
    width: 3.2rem;
    margin-bottom: 0.2rem;
  }
}

.mobile-footer p {
  color: #fff;
  font-size: 0.24rem;
  text-align: center;
}
.zhan-bottom {
  height: 3rem;
}
</style>