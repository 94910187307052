<template>
  <div>
    <div class="gz">
      <span>儲值規則</span>
      <p class="one">
        1.若您為未成年者，儲值前請經由法定代理人同意進行消費儲值。
      </p>
      <p>
        2.依據【通訊交易解除權合理例外情事使用準則】，本次購買之遊戲商場貨幣或商品不適用消費者保護法七天猶豫期規定，購買前請務必確認商品資訊，以免自身權益受損。
      </p>
    </div>
    <div class="jindu">
      <span class="p1" :class="jdnum == 1 ? 'p1_1' : ''"></span>
      <span class="p2" :class="jdnum == 2 ? 'p2_1' : ''"></span>
      <span class="p3" :class="jdnum == 3 ? 'p3_1' : ''"></span>
      <span class="p4" :class="jdnum == 4 ? 'p4_1' : ''"></span>
    </div>
    <div class="coentent">
      <div class="content-1" v-if="jdnum == 1">
        <form action="" method="get" class="srk">
          <select
            v-model="nowServe"
            aria-placeholder="請選擇伺服器"
            @change="handleChangeServe($event)"
          >
            <option selected hidden disabled value="">請選擇伺服器</option>
            <option :value="item.id" v-for="item in serveArr" :key="item.id">
              {{ item.name }}
            </option>
          </select>
          <select
            v-model="nowUser"
            :disabled="roleDisable"
            @change="handleChangeUser($event)"
          >
            <option selected hidden disabled value="">請選擇遊戲角色</option>
            <option :value="item.gid" v-for="item in roleArr" :key="item.gid">
              {{ item.name }}
            </option>
          </select>
          <input
            type="button"
            value="下一步"
            class="next"
            @click="handleNext()"
          />
        </form>
      </div>
      <div class="content-2" v-if="jdnum == 2 || jdnum == 4 || jdnum == 3">
        <form class="cxk" style="top: 3.7rem">
          <div class="search-box">
            <div class="search-input">
              <input
                type="text"
                v-model="shopKey"
                onkeypress="if (event.keyCode===13) return false"
                @keyup.enter="handleSearchShop"
                placeholder="請輸入商品名"
                class="sr"
              />
              <img src="@/assets/images/search.png" alt="" />
            </div>
            <input
              type="button"
              value="查詢"
              class="cx"
              @click="handleSearchShop()"
            />
          </div>
          <a @click="handleToggle()">更换角色</a>
        </form>
        <div class="qd">
          <div class="qd_nr">
            <a class="oo"><img src="@/assets/images/qd.png" /></a>
          </div>
        </div>
        <div class="wpk" v-loading="loading" element-loading-text="获取商品中">
          <div class="wpk-box" @scroll="handleScroll($event)">
            <div
              class="sp"
              v-for="(item, index) in shopArr"
              :key="index"
              @click="handleOk(item, index)"
              :class="index == okindex ? 'o2' : ''"
            >
              <span v-if="item.extraInfo"> {{ item.extraInfo }}</span>
              <img src="../assets/images/wp.png" />
              <p class="h2">{{ item.name }}</p>
              <p>{{ item.price / 100 }}（{{ item.currency }}）兌換</p>
            </div>
            <div class="no-info" v-if="noinfo">未找到相關商品，請重新輸入</div>
          </div>
        </div>
        <a class="pay" @click="handleBuy()">{{ buyTitle }}</a>
      </div>
    </div>
    <div class="tan_scuess" v-if="jdnum == 4 && orderFlag">
      <div class="tck2">
        <p>已完成儲值</p>
        <a href="javascript:;" class="out2" @click="handleReturn()">返回</a>
      </div>
    </div>
    <div class="tan_scuess" v-if="jdnum == 3">
      <div class="tck">
        <p>請盡快完成儲值</p>
        <a
          style="color: #1e85eb; margin-left: 0.66rem"
          class="out"
          @click="handleNo()"
          >返回</a
        >
        <a style="color: #fff" class="ok" @click="getOrderCheck()"
          >已完成儲值</a
        >
      </div>
    </div>
    <div class="tan_scuess error" v-if="jdnum == 4 && !orderFlag">
      <div class="tck2">
        <img src="@/assets/images/wenhao.png" alt="" />
        <p>未查詢到結果</p>
        <p class="notice">若已完成支付，請稍後於游戲内確認</p>
        <a href="javascript:;" class="out2" @click="handleReturn()">確認</a>
      </div>
    </div>
  </div>
</template>

<script>
import { game, mycard } from "@/proto/payload.js";
import {
  gameList,
  serverList,
  roleList,
  shopList,
  order,
  cheackOrder,
} from "@/api/game";
import { getAccessToken } from "@/util/auth";
import { Notification } from "element-ui";
import moment from "moment";
import { getUrlParams } from "@/util/params";

export default {
  name: "home",
  data() {
    return {
      jdnum: 1,
      okindex: null,
      shopArr: [],
      nowServe: "",
      nowUser: "",
      loading: true,
      serveArr: [],
      roleArr: [],
      roleDisable: true,
      shopKey: "",
      urlParams: "",
      pagination: {
        page: 1,
        pageSize: 10,
      },
      total: 0,
      serverItem: {},
      shopItem: {},
      userItem: {},
      changeFlag: false,
      orderFlag: false,
      buyTitle: "購買",
      noinfo: false,
    };
  },
  methods: {
    handleNext() {
      if (!this.nowUser) {
        Notification.warning({
          title: "提示",
          message: "請選擇游戲角色",
          duration: 3000,
        });
        return;
      }
      this.jdnum = 2;
      this.getShopList();
    },
    handleToggle() {
      this.jdnum = 1;
    },
    handleOk(item, index) {
      console.log(item, index);
      this.okindex = index;
      this.shopItem = item;
    },
    handleBuy() {
      if (this.buyTitle == "購買中") {
        return;
      }
      this.getOrder();
    },
    handleReturn() {
      this.jdnum = 2;
    },
    // 获取游戏列表
    getGameList() {
      gameList().then((res) => {
        console.log(res);
        const data = game.GameListResponse.toObject(res);
        console.log(data);
      });
    },
    // 获取区服列表
    getServerList() {
      const params = {
        appId: "8",
      };
      // console.log(params);
      serverList(params)
        .then((res) => {
          // const data =res;
          this.serveArr = res.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 切换区服
    handleChangeServe(e) {
      // console.log(e.target.value, "zzzzzzzzzzz");
      this.nowUser = "";
      this.serveArr.forEach((ele) => {
        if (ele.id == e.target.value) {
          this.serverItem = ele;
        }
      });
      this.getRoleList();
    },
    // 切换角色
    handleChangeUser(e) {
      this.roleArr.forEach((ele) => {
        if (ele.gid == e.target.value) {
          this.userItem = ele;
        }
      });
      console.log(this.userItem);
    },

    // 获取角色列表
    getRoleList() {
      const params = {
        // appId: "8",
        serverId: this.nowServe || "",
        // accessToken: getAccessToken(),
      };
      roleList(params)
        .then((res) => {
          this.roleDisable = false;
          // const data = res;
          this.roleArr = res.data;
          // console.log(data.list, data.list.length);
          if (res.data.length == 0) {
            Notification.info({
              title: "提示",
              message: "該服下暫無角色",
              duration: 3000,
            });
            return;
          }
        })
        .catch((res) => { });
    },

    // 获取商品列表
    getShopList() {
      const params = {
        // appId: "8",
        ...this.pagination,
        keys: this.shopKey,
        gid: this.nowUser,
        currency: "TWD",
        // accessToken: getAccessToken(),
        payType: 3,
        serverId: this.nowServe
      };
      this.loading = true;
      this.noinfo = false;
      shopList(params)
        .then((res) => {
          this.loading = false;
          if (res.code === 0) {
            const data = res.data;
            if (data.info.length === 0 && this.shopArr.length == 0) {
              this.noinfo = true;
              return;
            }
            this.shopArr = this.shopArr.concat(data.info);
            this.total = data.totalNum;
          }
        })
        .catch((res) => {
          this.loading = false;
          if (this.shopArr.length == 0) {
            this.noinfo = true;
          }
        });
    },

    // 搜索特定商品
    handleSearchShop() {
      this.shopArr = [];
      this.total = 0;
      this.pagination = {
        page: 1,
        pageSize: 10,
      };
      this.getShopList();
    },

    // 触底加载
    handleScroll(e) {
      const el = e.target;
      if (el.scrollTop + el.clientHeight >= el.scrollHeight) {
        if (this.total > this.shopArr.length) {
          this.pagination.page += 1;
          this.getShopList();
        }
      }
    },

    // 下单
    getOrder() {
      if (!this.shopItem.id) {
        Notification.warning({
          title: "提示",
          message: "請選擇商品",
          duration: 3000,
        });
        return;
      }
      this.buyTitle = "購買中";
      const params = {
        // appId: "8",
        channelID: "1",
        deviceID: "",
        cpOrderID: moment().unix(),
        extra: "",
        price: this.shopItem.price, //this.shopItem.price
        currency: "MYCARD",
        productID: this.shopItem.id.toString(), //
        productName: this.shopItem.name,
        productDesc: "",
        roleID: this.nowUser,
        roleName: this.userItem.name,
        roleLevel: "",
        serverID: this.nowServe,
        serverName: this.serverItem.name,
        // accessToken: getAccessToken(),
        area: "TWD",
      };
      order(params).then((res) => {
        this.jdnum = 3;
        const data = res.data;
        this.orderId = data.orderID;
        this.buyTitle = "購買";
        setTimeout(() => {
          window.open(data.transactionUrl, "_blank");
        });

      }).catch((res) => {
        this.buyTitle = "購買";
        if (res == 1) {
          Notification.error({
            message: "購買失敗，請重新購買",
            duration: 3000,
          });
        }
      });
    },

    // 验证下单
    getOrderCheck() {
      const params = {
        orderId: this.orderId,
        // accessToken: getAccessToken(),
      }

      cheackOrder(params).then((res) => {
        if (res.code === 0) {
          const data = res.data;
          if (data.status == 2 || data.status == 1) {
            this.jdnum = 4;
            this.orderFlag = false;
          }
          if (data.state == 3) {
            this.jdnum = 4;
            this.orderFlag = true;
          }
        }
        this.shopArr = [];
        this.pagination = {
          page: 1,
          pageSize: 10,
        };
        this.getShopList();
      });
    },

    // 返回
    handleNo() {
      this.jdnum = 2;
      this.shopArr = [];
      this.pagination = {
        page: 1,
        pageSize: 10,
      };
      this.getShopList();
    },
    // 支付成功
    handleSuccess() {
      this.jdnum = 4;
      this.changeFlag = false;
    },
  },

  mounted() {
    // this.getGameList();
    this.getServerList();
    const that = this;
  },
};
</script>

<style lang="less" scoped>
.gz {
  width: 7.6rem;
  position: absolute;
  top: 1rem;
  right: 2.4rem;
  text-align: left;
  span {
    font-size: 0.18rem;
    color: #b6b9bd;
  }
  p {
    font-size: 0.14rem;
    color: #b6b9bd;
  }
  .one {
    margin-top: 14px;
  }
}

.jindu {
  position: absolute;
  top: 2.8rem;
  height: 0.52rem;
  width: 14.3rem;
  z-index: 5;
  left: 2.45rem;
  .p1 {
    background: url(../assets/images/p1.png) center no-repeat;
    background-size: 3.56rem 0.52rem;
    margin-right: 0.1rem;
  }

  .p2 {
    background: url(../assets/images/p2.png) center no-repeat;
    background-size: 3.56rem 0.52rem;
    margin-right: 0.1rem;
  }

  .p3 {
    background: url(../assets/images/p3.png) center no-repeat;
    background-size: 3.56rem 0.52rem;
    margin-right: 0.1rem;
  }

  .p4 {
    background: url(../assets/images/p4.png) center no-repeat;
    background-size: 3.24rem 0.52rem;
    width: 3.24rem !important;
  }

  .p1_1 {
    background: url(../assets/images/p1_1.png) center no-repeat;
    background-size: 3.56rem 0.52rem;
  }

  .p2_1 {
    background: url(../assets/images/p2_1.png) center no-repeat;
    background-size: 3.56rem 0.52rem;
  }

  .p3_1 {
    background: url(../assets/images/p3_1.png) center no-repeat;
    background-size: 3.56rem 0.52rem;
  }

  .p4_1 {
    background: url(../assets/images/p4_1.png) center no-repeat;
    background-size: 3.24rem 0.52rem;
  }
}

.jindu span {
  width: 3.56rem;
  height: 0.52rem;
  display: block;
  float: left;
}

.srk {
  position: absolute;
  top: 4.6rem;
  left: 7.3rem;
  width: 4.6rem;
  height: 2.68rem;
}

.srk select {
  width: 4.58rem;
  height: 0.64rem;
  font-size: 0.22rem;
  color: #b6b9bd;
  margin-bottom: 0.3rem;
  color: #333333;
  padding-left: 0.2rem;
  position: relative;
  background: #f7f7f7 url(../assets/images/caret-bottom.png) no-repeat center;
  background-size: 0.25rem 0.25rem;
  background-position-x: 4.2rem;
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
}

.next {
  width: 4.58rem;
  height: 0.66rem;
  background: url(../assets/images/an.png) center no-repeat;
  background-size: 4.58rem 0.66rem;
  color: #fff;
  font-size: 0.28rem;
  font-weight: 400;
}

.content-2 {
  .cxk {
    position: absolute;
    top: 2.9rem;
    height: 0.52rem;
    width: 14.3rem;
    z-index: 5;
    left: 2.45rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .search-box {
      display: flex;
      align-items: center;

      .search-input {
        display: flex;
        align-items: center;
        width: 3.5rem;
        background: #fff;
        padding-right: 10px;
        margin-right: 0.2rem;
        .sr {
          font-size: 0.22rem;
          color: #b6b9bd;
          width: 2.74rem;
          height: 0.5rem;
          margin-right: 0.2rem;
          padding-left: 20px;
        }
        img {
          width: 0.25rem;
          height: 0.25rem;
        }
      }
    }

    .cx {
      width: 1.46rem;
      height: 0.5rem;
      background: url(../assets/images/an3.png) center no-repeat;
      background-size: 1.46rem 0.5rem;
      color: #fff;
      font-size: 0.22rem;
    }
    a {
      color: #8d9196;
      font-size: 0.24rem;
      text-decoration: underline;
      float: right;
    }
  }
}

.qd,
.wpk {
  position: absolute;
  top: 4.5rem;
}

.qd {
  width: 2.96rem;
  height: 3.46rem;
  background: #1c1d1e;
  left: 2.45rem;
  padding-top: 0.12rem;
  padding-left: 0.28rem;
}

.qd_nr {
  width: 2.68rem;
  height: 2.72rem;
  background: url(../assets/images/tong.jpg) center no-repeat;
  background-size: 2.68rem 3.34rem;
  padding-top: 0.62rem;
}

.qd_nr a {
  width: 2.68rem;
  height: 0.65rem;
  display: block;
  margin-bottom: 0.036rem;
}

.qd_nr a:hover {
  width: 2.68rem;
  height: 0.65rem;
  background: url(../assets/images/bb.png) center no-repeat;
  background-size: 2.68rem 0.65rem;
}

.qd_nr .oo {
  background: url(../assets/images/bb.png) center no-repeat;
  background-size: 2.68rem 0.65rem;
  img {
    width: 100%;
  }
}

.pay {
  width: 4.58rem;
  height: 0.66rem;
  position: absolute;
  background: url(../assets/images/an.png) center no-repeat;
  background-size: 4.58rem 0.66rem;
  color: #fff;
  font-size: 0.26rem;
  line-height: 0.66rem;
  text-align: center;
  top: 8.3rem;
  left: 7.31rem;
}

.wpk {
  width: 10.78rem;
  height: 3.58rem;
  right: 2.45rem;
  .wpk-box {
    width: 10.5rem;
    height: 3.46rem;
    background: #1c1d1e;
    padding-top: 0.12rem;
    padding-left: 0.28rem;
    overflow: scroll;
    overflow-x: hidden;
    position: relative;
  }

  .sp {
    width: 3.13rem;
    height: 1.57rem;
    background: url(../assets/images/wp_bg.jpg) center no-repeat;
    background-size: 3.13rem 1.57rem;
    position: relative;
    float: left;
    margin: 0.1rem;
    color: #fff;
  }
  .sp:hover {
    background: url(../assets/images/wp_bg2.jpg) center no-repeat;
    background-size: 3.13rem 1.57rem;
    color: #1d1d1e !important;
  }

  .sp span {
    width: 1.28rem;
    height: 0.52rem;
    position: absolute;
    background: url(../assets/images/ts.png) center no-repeat;
    background-size: 1.28rem 0.52rem;
    top: 0.12rem;
    left: 0;
    color: #fff;
    font-size: 0.15rem;
  }
  .sp img {
    width: 100%;
    margin-top: 0.16rem;
  }

  .sp p {
    text-align: center;
    font-size: 0.2rem;
  }

  .sp.h2 {
    font-size: 0.24rem;
  }
  .o2 {
    background: url(../assets/images/wp_bg2.jpg) center no-repeat;
    background-size: 3.13rem 1.57rem;
  }

  .o2 p {
    color: #1d1d1e !important;
  }
}

::-webkit-scrollbar {
  width: 0.4rem;
  height: 20px;
}

::-webkit-scrollbar-thumb {
  /*滚动条里面小方块*/
  box-shadow: inset 0 0 0.5rem rgba(0, 0, 0, 0.2);
  background: #535353;
}
::-webkit-scrollbar-track {
  /*滚动条里面轨道*/
  box-shadow: inset 0 0 0.5rem rgba(0, 0, 0, 0.2);
  background: #ededed;
}

.tan_scuess {
  width: 100%;
  height: 100%;
  position: absolute;
  background: rgba(0, 0, 0, 0.5);
  z-index: 20;
  .tck2 {
    text-align: center;
    width: 4.66rem;
    height: 3.48rem;
    position: absolute;
    background: url(../assets/images/tk.png) center no-repeat;
    background-size: 4.66rem 3.48rem;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 7;
    p {
      text-align: center;
      font-size: 0.32rem;
      margin-top: 1.96rem;
      margin-bottom: 0.3rem;
    }

    a {
      width: 2.3rem;
      height: 0.52rem;
      background: url(../assets/images/an2.png) center no-repeat;
      background-size: 2.3rem 0.52rem;
      display: block;
      color: #fff;
      text-align: center;
      font-size: 0.26rem;
      line-height: 0.52rem;
      margin-left: 1.18rem;
    }
  }
}

.error {
  .tck2 {
    background: url(../assets/images/dikuang-01.png) center no-repeat;
    img {
      margin-top: 0.3rem;
    }
    p {
      margin-top: 0.1rem;
      margin-bottom: 0rem;
      color: #000;
    }
    .notice {
      margin-top: 0;
      font-size: 0.2rem;
      margin-bottom: 0.2rem;
      color: #656a73;
    }
  }
}

.tck {
  width: 5.14rem;
  height: 2.16rem;
  position: absolute;
  background: url(../assets/images/tk2.png) center no-repeat;
  background-size: 5.14rem 2.16rem;
  color: #000;
  font-size: 0.32rem;
  text-align: center;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 7;
  p {
    margin-top: 0.48rem;
  }
  a {
    width: 1.76rem;
    height: 0.52rem;
    display: block;
    text-align: center;
    line-height: 0.52rem;
    float: left;
    font-size: 0.26rem;
    margin-right: 0.3rem;
    margin-top: 0.3rem;
  }
}

.no-info {
  width: calc(100% - 0.28rem);
  height: 100%;
  background: #bec5cd;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #717882;
  font-weight: bold;
  font-size: 0.3rem;
}
</style>
