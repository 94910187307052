<template>
  <div>
    <div class="top">
      <img src="@/assets/images/logo.png" class="logo" />
      <div class="name" @click="handleShow()">
        <img src="@/assets/images/tx.png" />
        <span>{{ userInfo?.name || "用戶名" }}</span>
        <div class="ddh" v-if="showFlag == true">
          <span @click.stop="handleRecord()" key="1122">歷史交易</span>
          <span id="logout" @click="handleOut()">登出</span>
        </div>
      </div>
    </div>
    <div class="zan"></div>

    <div class="nr">
      <router-view />
    </div>

    <div class="footer">
      <p>Copyright © 2022</p>
      <p>香港樂曼多有限公司保留一切權利</p>
    </div>
  </div>
</template>

<script>
import {
  removeAccessToken,
  removeRefreshToken,
  removeUserInfo,
  getUserInfo,
} from "@/util/auth";

export default {
  name: "layout",
  data() {
    return {
      showFlag: false,
      userInfo: JSON.parse(getUserInfo()),
    };
  },
  methods: {
    handleShow() {
      this.showFlag = !this.showFlag;
    },
    handleOut() {
      this.showFlag = false;
      removeAccessToken();
      removeRefreshToken();
      removeUserInfo();
      location.reload();
    },
    handleRecord() {
      this.showFlag = !this.showFlag;
      console.log(this.showFlag);
      this.$router.push({
        path: "/record",
      });
    },
  },
};
</script>

<style lang="less" scoped>
.nr {
  width: 100%;
  height: 10.4rem;
  background: url(../assets/images/background.png) center no-repeat;
  background-size: 100% 10.4rem;
  position: relative;
}
.zan {
  width: 100%;
  height: 1rem;
}
</style>