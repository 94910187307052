<template>
  <div id="app">
    <pc v-if="isPC" />
    <mobile v-else />
  </div>
</template>
<script>
import { isPC } from "./util/common";
import mobile from "@/views/mobile";
import pc from "@/views/pc";
export default {
  name: "app",
  components: {
    mobile,
    pc,
  },
  data() {
    return {
      isPC: isPC(),
    };
  },
  mounted() {
    console.log(isPC());
  },
};
</script>

<style lang="less">
</style>
