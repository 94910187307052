<template>
  <div>
    <router-view />
  </div>
</template>

<script>
export default {
  mounted() {
    function windowsize() {
      var w = window.innerWidth;
      document.documentElement.style.fontSize = (w / 1920) * 100 + "px";
    }
    window.addEventListener("resize", windowsize);
    windowsize();
  },
};
</script>

<style>
</style>