import Vue from 'vue'
import VueRouter from 'vue-router'
import login from '../views/login.vue'
import Layout from '@/components/layout.vue'
import home from '@/views/home.vue'
import record from '@/views/record.vue'
import success from '@/views/success.vue'
import moblieLogin from '@/views/mobileVIew/mobileLogin.vue'
import mobileLayout from '@/components/mobileLayout.vue'
import mobileHome from '@/views/mobileVIew/mobileHome.vue'
import mobileRecord from '@/views/mobileVIew/mobileRecord.vue'
import mobileSuccess from '@/views/mobileVIew/mobileSuccess.vue'

import { isPC } from "@/util/common";
Vue.use(VueRouter)

let routes = []

if (isPC()) {
  const pcRouter = [
    {
      path: '/login',
      component: login
    },
    {
      path: '/',
      component: Layout,
      redirect: '/home',
      children: [
        {
          path: 'home',
          component: home,
          name: '角色咨询'
        },
        {
          path: 'record',
          component: record,
          name: '充值記錄'
        },
        {
          path: 'success',
          component: success,
          name: '充值成功'
        }
      ],
    },
  ]
  routes = pcRouter
} else {
  const mobileRouter = [
    {
      path: '/login',
      component: moblieLogin
    },
    {
      path: '/',
      component: mobileLayout,
      redirect: '/home',
      children: [
        {
          path: 'home',
          component: mobileHome,
          name: '角色咨询'
        },
        {
          path: 'record',
          component: mobileRecord,
          name: '充值記錄'
        },
        {
          path: 'success',
          component: mobileSuccess,
          name: '充值成功'
        }
      ],
    }
  ]
  routes = mobileRouter
}




const router = new VueRouter({
  mode: 'history',
  base:'/recharge',
  routes
})

export default router
