import request from '@/util/request'
// import { passport } from '@/proto/payload.js'

export function login(data) {
    return request({
        url: '/v1/account/web/lmdLogin',
        method: 'POST',
        data: data
    })
}

export function rdLogin(data) {
    return request({
        url: '/v1/account/web/trilateralLogin',
        method: 'POST',
        data: data
        // resType: passport.LoginResponse
    })
}

export function refreshToken(data) {
    return request({
        url: '/v1/passport/token',
        method: 'PUT',
        data: data
        // resType: passport.LoginResponse
    })
}