
// 获取url地址参数
export const getUrlParams = (url) => {
    const theRequest = new Object
    if (url.indexOf('?') !== -1) {
      const str = url.substr(1)
      const strs = str.split('&')
      for (let i = 0; i < strs.length; i++) {
        theRequest[strs[i].split('=')[0]] =strs[i].split('=')[1]
      }
    }
    return theRequest
  }

  
  