<template>
  <div class="mobile-login">
    <img class="bg" src="../../assets/mobile/bg-01.png" alt="" />
    <div class="login-box">
      <div class="box">
        <p class="tit">LMD帳號登入</p>
        <img class="tit-line" src="@/assets/images/line.png" alt="" />
        <div class="uername">
          <select v-model="phonePrefix">
            <option selected hidden disabled value="">請選擇</option>
            <option :value="item" v-for="item in phoneTop" :key="item">
              {{ item }}
            </option>
          </select>
          <input
            placeholder="請輸入帳號"
            v-model="username"
            id="username"
            type="number"
            @blur="handleUserBulr"
            @focus="handleUserFocus"
          />
        </div>
        <p class="error">{{ isError ? "請輸入正確的手機號碼" : "" }}</p>
        <div class="password-box">
          <img src="@/assets/images/password.png" alt="" />
          <input
            placeholder="請輸入密碼"
            type="password"
            id="password"
            v-model="password"
            @blur="handelPassBulr"
            class="password"
          />
        </div>
        <p class="error">{{ isErrorPass ? "請輸入6-16位密碼" : "" }}</p>
        <input
          type="submit"
          value="登入"
          class="dr"
          @click="handleSubmit()"
          style="margin-bottom: 0.1rem"
        />
        <div style="font-size: 0.18rem; color: #101010" class="info-box">
          <div class="link-box">
            <a href="http://nbasupstar.lmdgame.tw/privacy/" target="_blank"
              >隱私政策</a
            >
            <a
              href="http://nbasupstar.lmdgame.tw/contract/"
              target="_blank"
              style="margin-left: 10px"
              >用戶協議</a
            >
          </div>
        </div>
        <p style="font-size: 0.2rem; color: #101010;">
          使用第三方平臺帳號登入
        </p>
        <button class="other apple" id="apple-login" @click="handleIOS">
          <span></span>
        </button>
        <fb-signin-button
          :params="fbSignInParams"
          @success="onFbSignInSuccess"
          @error="onFbSignInError"
        >
          <a class="other face"></a>
        </fb-signin-button>
        <a class="other g_id_signin" id="g_id_signin">
          <div></div>
        </a>
      </div>
    </div>
    <div class="mobile-footer">
      <img src="../../assets/mobile/logo-01.png" alt="" />
      <p>Copyright © 2022</p>
      <p>香港樂曼多有限公司保留一切權利</p>
    </div>
  </div>
</template>

<script>
import { login, rdLogin } from "@/api/login";
import { gameList } from "@/api/game";
import moment from "moment";
import { passport, enums, game } from "@/proto/payload.js";
import { Notification } from "element-ui";
import { setAccessToken, setRefreshToken, setUserInfo } from "@/util/auth.js";
import store from "@/store";
import { checkPhone, checkPassWord } from "@/util/check.js";

const LoginType = enums.LoginType;
const phoneTop = ["+886", "+852", "+853", "+60", "+65", "+86", "+63", "+62"];
export default {
  data() {
    return {
      username: "",
      password: "",
      googleSignInParams: {
        client_id:
          "498606738451-ufrut3a7nu3m2023kg06udf2r506627a.apps.googleusercontent.com",
      },
      fbSignInParams: {
        scope: "email",
        return_scopes: true,
      },
      phoneTop: phoneTop,
      phonePrefix: "+886",
      isError: false,
      isErrorPass: false,
    };
  },
  methods: {
    handleSubmit() {
      if (checkPhone(this.phonePrefix, this.username)) {
        return;
      }
      if (checkPassWord(this.password)) {
        return;
      }
      const params = {
        appId: "8",
        phonePrefix: this.phonePrefix,
        phone: this.username.trim(),
        password: this.password,
      };
      const loading = this.$loading({
        lock: true,
        text: "登陸中",
        spinner: "el-icon-loading",
      });
      login(params)
        .then((res) => {

          if (res.code === 0) {
            const data = res.data;
            const userInfo = {
              name: data.accounts.otherPlatName,
            };
            setAccessToken(data.info.access_token);
            setRefreshToken(data.info.refresh_token);
            setUserInfo(JSON.stringify(userInfo));
            loading.close();
            this.$router.push({
              path: "/home",
            });
          } else {
            loading.close();
            Notification.error({
              message: "賬號或密碼錯誤",
              duration: 3000,
            });
          }
        })
        .catch((res) => {
          loading.close();
          // if (res == 1) {
          //   Notification.error({
          //     message: "賬號或密碼錯誤",
          //     duration: 3000,
          //   });
          // }
        });
    },
    // facebook
    onFbSignInSuccess(response) {
      const profile = response.authResponse.accessToken;
      this.getRdLogin(profile, LoginType.LOGIN_BY_FB);
    },
    onFbSignInError(error) {
      console.log("OH NOES", error);
    },

    // ios
    async handleIOS() {
      const data = await AppleID.auth.signIn();
      this.getRdLogin(data.authorization.id_token, LoginType.LOGIN_BY_APPLE);
    },

    // 谷歌第三方登录
    getRdLogin(key, item) {
      const params ={
        appId: "8",
        account: key,
        loginType: item,
      };
      rdLogin(params)
        .then((res) => {
          const data = res.data;
          const userInfo = {
            name: data.accounts.otherPlatName,
          };
          setAccessToken(data.info.access_token);
          setRefreshToken(data.info.refresh_token);
          setUserInfo(JSON.stringify(userInfo));
          this.$router.push({
            path: "/home",
          });
        })
        .catch((res) => {
          if (res == 1) {
            Notification.error({
              message: "帳號不存在",
              duration: 3000,
            });
          }
        });
    },

    // 获取手机号前缀
    getGamneList() {
      gameList().then((res) => {
        const data = game.GameListResponse.toObject(res);
        this.phoneTop = data.phonePrefix || [];
      });
    },

    // 账号失焦
    handleUserBulr() {
      this.isError = checkPhone(this.phonePrefix, this.username);
      console.log(this.isError);
    },
    // 账号聚焦
    handleUserFocus() {
      this.isError = false;
    },
    // 密码失焦
    handelPassBulr() {
      this.isErrorPass = checkPassWord(this.password);
    },

    // 选择手机号前缀
    handleChangeServe() {
      if (this.username.length == 0) {
        this.isError = false;
        return;
      }
      this.isError = checkPhone(this.phonePrefix, this.username);
    },
    handleCredentialResponse(e) {
      this.getRdLogin(e.credential, LoginType.LOGIN_BY_GOOGLE);
    },
  },
  mounted() {
    // this.getGamneList();
    AppleID.auth.init({
      response_type: "code",
      clientId: "com.lmdgame.nbamvp.tw.web",
      scope: "email",
      redirectURI: "https://nbapay.lmdgame.tw/api/v1/sign/apple",
      usePopup: true,
      state: "state",
    });
    document.addEventListener("AppleIDSignInOnSuccess", (data) => {
      // console.log(data, "ios success");
    });
    document.addEventListener("AppleIDSignInOnFailure", (error) => {
      // console.log(error, "ios error");
    });
  },
  created() {
    const script = document.createElement("script");
    script.src = "https://accounts.google.com/gsi/client";
    document.body.appendChild(script);

    window.addEventListener("load", () => {
      window.google.accounts.id.initialize({
        // 主要就是填写client_id
        client_id:
          "498606738451-ufrut3a7nu3m2023kg06udf2r506627a.apps.googleusercontent.com",
        callback: this.handleCredentialResponse,
      });
      window.google.accounts.id.prompt(false);
      // 设置按钮的样式等
      window.google.accounts.id.renderButton(
        document.getElementById("g_id_signin"),
        {
          size: "small",
          type: "icon",
          text: "signin_with",
          shape: "circle",
        }
      );
    });
  },
};
</script>

<style lang="less" scoped>
.mobile-login {
  width: 100%;
  min-height: 100vh;
  position: relative;
  .bg {
    position: absolute;
    width: 100%;
    height: 100vh;
    display: block;
  }
  .login-box {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    .box {
      margin-top: 6.5rem;
      width: 6.9rem;
      height: 6.7rem;
      position: relative;
      background: url(../../assets/mobile/dikuang-01.png) no-repeat;
      background-size: 100%;
      box-sizing: border-box;
      padding: 0.7rem;
      .tit {
        font-size: 0.35rem;
        font-weight: 600;
        margin-bottom: 0.15rem;
      }
      .tit-line {
        display: block;
        width: 100%;
      }
      .uername {
        margin-top: 0.3rem;
        display: flex;
        align-items: center;
        select {
          width: 1.55rem;
          height: 0.8rem;
          background: #e0e0e0 url(../../assets/images/caret-bottom.png)
            no-repeat center;
          background-size: 0.3rem 0.3rem;
          background-position-x: 1.1rem;
          appearance: none;
          -moz-appearance: none;
          -webkit-appearance: none;
          font-size: 0.22rem;
          color: #b6b9bd;
          padding-left: 0.3rem;
          border-right: 1px solid #c7c9cb;
          box-sizing: border-box;
          option {
            width: 1.8rem;
          }
        }
        input {
          border-radius: 0;
          width: 4.05rem;
          height: 0.8rem;
          background-color: #fff;
          color: #b6b9bd;
          font-size: 0.22rem;
          margin: 0;
          box-sizing: border-box;
          // background: url(../assets/images/accoun_2.png) no-repeat !important;
          padding-left: 0.2rem;
          padding-right: 0.2rem;
        }
      }
      .password-box {
        background-color: #fff;
        display: flex;
        align-items: center;
        padding-left: 0.2rem;
        width: 5.5rem;
        height: 0.8rem;
        box-sizing: border-box;
        img {
          width: 0.17rem;
          display: flex;
        }
        .password {
          padding-left: 0.2rem;
          box-sizing: border-box;
          background-color: #fff !important;
          margin-bottom: 0;
          color: #b6b9bd;
          font-size: 0.22rem;
          width: 5.5rem;
        }
      }
      .dr {
        margin-left: 0 !important;
        font-size: 0.28rem !important;
        color: #fff !important;
        width: 100% !important;
        font-weight: 600;
        background: url(../../assets/mobile/dikuang-02.png) no-repeat;
        background-size: 100% 100%;
        cursor: pointer;
        height: 0.7rem !important;
        border-radius: 0;
      }
    }
  }
}

.other {
  width: 0.45rem;
  height: 0.45rem;
  float: left;
  margin-right: 0.2rem;
  margin-top: 0.1rem;
  display: block;
}

.apple {
  background: url(../../assets/images/apple.png) center no-repeat;
  background-size: 0.45rem 0.45rem;
}

.face {
  background: url(../../assets/images/face.png) center no-repeat;
  background-size: 0.45rem 0.45rem;
}

.google {
  background: url(../../assets/images/google.png) center no-repeat;
  background-size: 0.4rem 0.4rem;
}

.mobile-footer {
  width: 100%;
  height: 2rem;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background: url("../../assets/mobile/bottom.png");
  background-size: 100% 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  padding-top: 0.5rem;
  img {
    width: 3.2rem;
    margin-bottom: 0.2rem;
  }
}

.mobile-footer p {
  color: #fff;
  font-size: 0.24rem;
  text-align: center;
}

.error {
  width: 100%;
  height: 0.4rem;
  line-height: 0.4rem;
  color: rgb(245, 108, 108);
  font-size: 0.15rem;
  font-family: "Helvetica Neue", Helvetica, "PingFang SC", "Hiragino Sans GB",
    "Microsoft YaHei", SimSun, sans-serif;
}
#g_id_signin {
}
</style>