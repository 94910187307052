<template>
  <div class="mobile-success">
    <div class="content"></div>
    <div class="ying"></div>
    <div class="tck2" v-if="flag">
      <p>已完成儲值</p>
      <a href="javascript:;" class="out2" id="out2" @click="handleOk()">確認</a>
    </div>
    <div class="tck2 error" v-if="!flag">
      <img src="@/assets/images/wenhao.png" alt="" />
      <p>{{ msg }}</p>
      <p class="notice"></p>
      <a href="javascript:;" class="out2" @click="handleOk()">確認</a>
    </div>
  </div>
</template>

<script>
import { getUrlParams } from "@/util/params";
export default {
  data() {
    return {
      flag: true,
      msg: "",
    };
  },
  methods: {
    handleOk() {
      this.$router.push("/home");
    },
  },
  created() {
    this.flag = true;
    const url = window.location.search;
    const is_success = getUrlParams(url);
    if (is_success.is_success != "3") {
      const msg = is_success.msg;
      this.msg = msg;
      this.flag = false;
    }

  },
};
</script>


<style lang="less" scoped>
body {
  background: #cfcfcf;
}

.ying {
  width: 100%;
  height: 100%;
  background: #000;
  z-index: 6;
  opacity: 0.8;
  position: absolute;
  top: 0;
  left: 0;
}

.content {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  /* background: url(./images/logo_bg.jpg) center no-repeat; */
}

.tck2 {
  width: 4.66rem;
  height: 3.48rem;
  position: absolute;
  background: url(../../assets/images/tk.png) center no-repeat;
  background-size: 4.66rem 3.48rem;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 7;
}

.tck2 p {
  text-align: center;
  font-size: 0.32rem;
  margin-top: 1.96rem;
  margin-bottom: 0.3rem;
}

.tck2 a {
  width: 2.3rem;
  height: 0.52rem;
  background: url(../../assets/images/an2.png) center no-repeat;
  background-size: 2.3rem 0.52rem;
  display: block;
  color: #fff;
  text-align: center;
  font-size: 0.26rem;
  line-height: 0.52rem;
  margin-left: 1.18rem;
}

.error {
  text-align: center;
  background: url(../../assets/images/dikuang-01.png) center no-repeat;

  img {
    width: 1.5rem;
    margin-top: 0.3rem;
  }

  p {
    margin-top: 0.1rem;
    margin-bottom: 0rem;
    color: #000;
  }

  .notice {
    margin-top: 0;
    font-size: 0.2rem;
    margin-bottom: 0.2rem;
    color: #656a73;
  }
}
</style>